import {
  getAllCompanies,
  registerNewCustomer,
  updateCompany,
} from "../../../../api/services/SubcriptionServices";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import {
  ERROR,
  SUCCESS,
} from "../../../../components/general/app-toast/AppToast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
export const INITIAL_STATE = {
  loading: false,
  reason: "",
};
const Logic = (dispatch, state, prop) => {
    
  const { addToast } = UseAppToast();
  const updateCustomer = (status) => {
    dispatch({
      payload: [{ type: UPDATE_PROP, prop: "loading", value: true }],
    });
    let params = {
      id: prop.targetCompany.id,
      status: status,
      reason: state.reason,
    };
    // debugger;
    updateCompany(
      (res) => {
        // debugger;

        // alert(JSON.stringify(res.data))
        prop.hide();
        addToast({
          type: SUCCESS,
          description: res?.data?.message,
          title: "Success",
        });
      },
      (err) => {
        // debugger;
        addToast({
          type: ERROR,
          description: err?.response?.data?.message,
          title: "Success",
        });
      },
      (f) => {
        // debugger;
        dispatch({
          payload: [{ type: UPDATE_PROP, prop: "loading", value: false }],
        });
      },
      params
    );
    
  };
  return {
    actions: { updateCustomer },
  };
};

export default Logic;
