import { useMemo } from "react";
import {
  getAllCompanies,
  getSubscriptionsRevenue,
} from "../../api/services/SubcriptionServices";
import { fetchPosts, fetchUserCount } from "../../api/services/UserServices";
import UseAppTable from "../../helpers/hooks/use-app-table";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { Columns } from "./columns";

export const INITIAL_STATE = {
  customerLoading: true,
  chartPercent: 10,
  chartEstimatedRevenue: 20,
  usersLoading: false,
  chartData: [],
  usersChartData: {
    labels: ["Plan 1", "Plan 2", "Plan 3", "Corporate"],
    datasets: [
      {
        label: "users count",
        data: ["20", "10", 30, 40],
        backgroundColor: "#2e5bff",
      },
    ],
  },
  BarChart: {
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 400,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 5, 
        },
      },
      fill: {
        opacity: 0.5, 
      },
    },
  },
  
};
const Logic = (dispatch, state) => {
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchPosts,
  });
  let columns = useMemo(() => Columns, []);
  // const usersCount= ()=>{}
  // dispatch({
  //   payload: [{type:UPDATE_PROP ,prop:'usersLoading', value:true}]
  // })
  const getUserCounts = () => {
    fetchUserCount(
      (res) => {
        const totalUsers = res.data.response.total_users;
        const companies = res.data.response.companies;

        const BarChartSeries = companies.map(
          (company) => company.user_count
        );

        const BarChartCategories = companies.map(
          (company) => company.name
        );

        const BarChart = {
          series: [
            {
              name: "No of Users",
              data: BarChartSeries,
            },
          ],
          options: {
            xaxis: {
              categories: BarChartCategories,
            },
          },
        };
        dispatch({
          payload: [
            { type: UPDATE_PROP, prop: "BarChart", value: BarChart },
            { type: UPDATE_PROP, prop: "usersLoading", value: false },
            { type: UPDATE_PROP, prop: "totalUsers", value: totalUsers },
          ],
        });

      },
      (err) => {},
      () => {},
      {}
    );
  };
  const getCompanies = () => {
    getAllCompanies(
      (res) => {
        const totalCustomers = res.data.response.total;
        
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: "totalCustomers",
              value: totalCustomers,
            },
            { type: UPDATE_PROP, prop: "customerLoading", value: false },
          ],
        });
      },
      (err) => {},
      () => {},
      {}
    );
  };

  const getAllSubscriptions = () => {
    getSubscriptionsRevenue(
      (res) => {
        console.log("API Response:", res.data.response);
        const plans = res.data.response.plans;
        const totalEstimatedRevenue = res.data.response.estimated_revenue;

        const chartData = plans.map((plan) => ({
          labels: [plan.name],
          datasets: [
            {
              data: [plan.revenue, totalEstimatedRevenue - plan.revenue],
              backgroundColor: ["#2e5bff", "#d2d2d2"],
            },
          ],
        }));

        dispatch({
          payload: [
            { type: UPDATE_PROP, prop: "chartData", value: chartData },
            {
              type: UPDATE_PROP,
              prop: "totalEstimatedRevenue",
              value: totalEstimatedRevenue,
            },
          ],
        });
      },
      (err) => {},
      () => {}
    );
  };

  return {
    actions: {
      setData,
    },
    data,
    error,
    columns,
    getCompanies,
    getAllSubscriptions,
    getUserCounts,
  };
};

export default Logic;
