import { faCheck, faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../app-button/AppButton";
import Styles from "./AppFilters.module.css";
import Overlay from "react-bootstrap/Overlay";
import { useEffect, useRef, useState } from "react";
import DisplayFilters from "./display-filters/DisplayFilters";
import Label from "../label/Label";
import AppSelect from "../app-select/AppSelect";
import FilterType from "./filter-type/FilterType";
const AppFilters = ({
  children,
  filterData,
  filterKeys,
  filters,
  setData,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [filterInput, setFilterInput] = useState([]);
  const [placement, setPlacement] = useState("right-start");
  const initalState = [
    {
      key: filterData[0]?.key,
      sign: filterData[0]?.sign[0].value,
      signLabel: filterData[0]?.sign[0].label,
      label: filterData[0]?.label,
      value: "",
      value2: "",
    },
  ];
  const showFilterInputs = () => {
    if (filterData.length > 0) {
      setShow(!show);
    }
    resetFilterInput();
  };
  const addFilterInput = () => {
    setFilterInput([...filterInput, ...initalState]);
  };
  const setFilterValues = () => {
    console.log(filterInput);

    let newFilterInput = filterInput.filter((element) =>
      element.sign == "is_between"
        ? element.value != "" && element.value2 != ""
        : element.value != "" ||
          element.sign == "is_not_set" ||
          element.sign == "is_set"
    );
    if (newFilterInput.length != 0) {
      setData((prevState) => {
        return {
          ...prevState,
          params: {
            ...prevState.params,
            filters: [...prevState.params.filters, ...newFilterInput],
          },
        };
      });
    }

    resetFilterInput();
    setShow(false);
  };
  const resetFilterInput = () => {
    setFilterInput([...initalState]);
  };
  const handleDelete = (index) => {
    let arrfilters = [...filters];
    arrfilters.splice(index, 1);

    setData((prevState) => {
      return {
        ...prevState,
        params: {
          ...prevState.params,
          filters: [...arrfilters],
        },
      };
    });
  };
  let timer = null;
  const setFilterValue = (value, index) => {
    clearTimeout(timer);
    timer = setTimeout(
      () => {
        setFilterInput([
          ...filterInput.slice(0, index),
          { ...filterInput[index], ...value },
          ...filterInput.slice(index + 1),
        ]);
      },

      100
    );
  };

  useEffect(() => {
    timer = null;
  }, []);

  return (
    <div className={Styles.container}>
      {/* <div>Filters</div> */}
      <DisplayFilters filters={filters} handleDelete={handleDelete} />

      <div className={Styles.dropdown}>
        <AppButton
          ref={target}
          onClick={showFilterInputs}
          value={
            <FontAwesomeIcon icon={faFilter} style={{ alignSelf: "center" }} />
          }
          style={{
            padding: "2px 3px",
            fontSize: 15,
          }}
        />
        <Overlay
          target={target.current}
          show={show}
          placement={placement}
          aria-hidden="true"
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                padding: "0px 10px",
                color: "white",
                borderRadius: 3,
                zIndex: 9999,
                ...props.style,
              }}
            >
              <div className={Styles.menu}>
                {filterInput.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "12px",
                      color: "black",
                    }}
                    key={index}
                  >
                    <Label
                      name="Filter Field"
                      className={Styles.label}
                      classText={Styles.labelText}
                      // className={Styles.label}
                      style={{ flex: 2, margin: "8px 5px" }}
                    >
                      <AppSelect
                        options={filterData.map((element) => ({
                          value: element.key,
                          label: element.label,
                        }))}
                        styles={{
                          dropdownIndicator: (provided, state) => {
                            return {
                              ...provided,
                              color: "var(--primary)",
                              fontSize: 12,
                            };
                          },
                          valueContainer: (provided, state) => {
                            return { ...provided, padding: "0px" };
                          },
                        }}
                        onChange={(e) => {
                          const newFilterInput = [
                            ...filterInput.slice(0, index),
                            {
                              ...filterInput[index],
                              key: e.value,
                              label: e.label,
                              sign: filterKeys[e.value].sign[0].value,
                              signLabel: filterKeys[e.value].sign[0].label,
                            },
                            ...filterInput.slice(index + 1),
                          ];
                          setFilterInput(newFilterInput);
                        }}
                        defaultValue={filterInput[index].key}
                      />
                    </Label>
                    <Label
                      name="Condition"
                      className={Styles.label}
                      classText={Styles.labelText}
                      style={{ flex: 2, margin: "8px 5px" }}
                    >
                      <AppSelect
                        styles={{
                          dropdownIndicator: (provided, state) => {
                            return {
                              ...provided,
                              color: "var(--primary)",
                              fontSize: 12,
                            };
                          },
                          valueContainer: (provided, state) => {
                            return { ...provided, padding: "0px" };
                          },
                        }}
                        onChange={(e) => {
                          const newFilterInput = [
                            ...filterInput.slice(0, index),
                            {
                              ...filterInput[index],
                              sign: e.value,
                              signLabel: e.label,
                            },
                            ...filterInput.slice(index + 1),
                          ];
                          setFilterInput(newFilterInput);
                        }}
                        options={filterKeys[filterInput[index].key].sign.map(
                          (element) => ({
                            value: element.value,
                            label: element.label,
                          })
                        )}
                        defaultValue={filterInput[index].sign}
                      />
                      {/* {index} */}
                    </Label>
                    <FilterType
                      sign={filterInput[index].sign}
                      type={filterKeys[filterInput[index].key].type}
                      dropdown={filterKeys[filterInput[index].key].dropdown}
                      index={index}
                      filterInput={filterInput}
                      setFilterInput={setFilterInput}
                      setFilterValue={setFilterValue}
                    />
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <AppButton
                    classes="buttonIcon"
                    className={Styles.buttonFilter}
                    value={
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ alignSelf: "center" }}
                      />
                    }
                    onClick={addFilterInput}
                  />
                  <AppButton
                    classes="buttonIcon"
                    className={Styles.buttonFilter}
                    value={
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ alignSelf: "center" }}
                      />
                    }
                    onClick={setFilterValues}
                  />
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
};

export default AppFilters;
