import React, { useMemo, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import UseAppTable from "../../../helpers/hooks/use-app-table";
import TableLayout from "../../../components/general/table-layout/TableLayout";
import { getAllTickets } from "../../../api/services/TicketsServices";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import Styles from "./Tickets.module.css";
import Table from "../../../components/general/app-table/AppTable";
import Title from "../../../components/general/title/Title";
import NumberWithLabel from "../../../components/general/number-with-label/NumberWithLabel";
import TotalContainer from "../../../components/general/total-container/TotalContainer";
import Card from "../../../components/general/card/Card";
import ConfirmModal from "../../../components/general/confirm-modal/ConfrimModal";
import { useNavigate } from "react-router-dom";
import useLogic from "../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";

const Tickets = () => {
  // request  data
  const {
    state,
    updateState,
    actions,
    data,
    error,
    setData,
    columns,
    setShowDeleteAlert,
    HandleCloseDelete,
    HandelDelete,
    showDeleteAlert,

  } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const filterData = [
    {
      key: "tickets.ticket_number",
      label: "Ticket Number",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "tickets.type",
      label: "Type",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "tickets.updated_at",
      label: "Last Activity",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "tickets.status",
      label: "Status",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "companies.contact_person_name",
      label: "Contact Person",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "companies.contact_person_mobile",
      label: "Mobile",
      sign: [{ value: "contains", label: "contains" }],
    },
    {
      key: "companies.contact_person_email",
      label: "Email",
      sign: [{ value: "is_between", label: "isBetween" }],
    },

    // ... other filters
  ];

  const filterKeys = {
    "tickets.ticket_number": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "tickets.type": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "companies.contact_person_name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "companies.contact_person_email": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "companies.contact_person_mobile": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "tickets.status": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "contains" },
        { value: "is_equal", label: "equals" },
        // ... other signs
      ],
    },
    "tickets.updated_at": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "equals" },
        { value: "is_between", label: "isBetween" },
        { value: "greater_than", label: "greaterThan" },
        { value: "less_than", label: "lessThan" },
        { value: "greater_than_or_equal_to", label: "greaterThanOrEqual" },
        { value: "less_than_or_equal_to", label: "lessThanOrEqual" },

        // ... other signs
      ],
    },
  };

  return (
    <>
      {/* <div className={Styles.title}>
                <Title title={`${"Tickets"}(${data.total})`}></Title>
            </div> */}
      <Card
        style={{ padding: "0 10px", marginBottom: "20px", display: "flex" }}
      >
        <NumberWithLabel
          className={Styles.totaltickets}
          number={state.allTicketsTotal}
          label="Inquiries/Tickets"
          textConatinerClassname={Styles.textConatiner}
          contentClassname={Styles.content}
        >
          <TotalContainer
            data={
              Array.isArray(state?.inquiriesAndtickets)
                ? state.inquiriesAndtickets
                : []
            }
          />
        </NumberWithLabel>
      </Card>

      <TableLayout
        title={`${"Tickets"}(${data?.total})`}
        tableColumns={columns}
        setData={setData}
        data={data}
        error={error}
        filterData={{ filterData: filterData, filterKeys: filterKeys }}
        showFilter={true}
        showPagination={true}
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
      />

{showDeleteAlert && (
        <ConfirmModal
          show={showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => HandelDelete()}
          message={"Are you sure you want to delete?"}
        />
      )}
    </>
  );
};
export default Tickets;
