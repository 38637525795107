import React, { useEffect } from "react";
import Routes from "./routes";
import "./assets/styles/index.css";
import { LANGUAGE } from "./helpers/constants/StaticKeys";
import { toggleLanguage } from "./locale/i18n";
import ToastPortal from "./components/general/app-toast/ToastPortal";

function App() {
  useEffect(() => {
    let lang = localStorage.getItem(LANGUAGE);
    if (lang != null) {
      toggleLanguage(lang);
    }
  }, []);
  return (
    <>
      <Routes />
      <ToastPortal autoClose={true} autoCloseTime={10000} />
    </>
  );
}

export default App;
