import React from "react";
import styles from "./AttachmentIcon.module.css";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AttachmentIcon = ({ attachment, onDelete }) => {
  const { addToast } = UseAppToast();
  const getAttachmentURL = (attachment) => {
    if (attachment instanceof File || attachment instanceof Blob) {
      return URL.createObjectURL(attachment);
    }
    return attachment.url;
  };
  console.log("attachment from Chatter: ", getAttachmentURL(attachment));
  return (
    <div className={styles.attachmentWrapper}>
      {getAttachmentURL(attachment) ? (
        attachment.name.endsWith(".jpg") ||
        attachment.name.endsWith(".png") ||
        attachment.name.endsWith(".jpeg") ? (
          <img
            src={getAttachmentURL(attachment)}
            alt={`Attachment ${attachment.id}`}
            className={styles.imageAttachment}
          />
        ) : (
          (attachment.name.endsWith(".pdf") ||
            attachment.name.endsWith(".doc") ||
            attachment.name.endsWith(".docx") ||
            attachment.name.endsWith(".xls") ||
            attachment.name.endsWith(".xlsx")) && (
            <div className={styles.pdfContainer}>
              <p>
                {attachment.name.substring(
                  attachment.name.lastIndexOf(".") + 1
                )}
              </p>
            </div>
          )
        )
      ) : (
        addToast({
          type: ERROR,
          description: "Not supported file",
          title: "Error",
        })
      )}
      <span
        className={styles.deleteIcon}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <FontAwesomeIcon
          icon={faTimes}
          style={{ textAlign: "center", alignSelf: "center", margin: "5px" }}
        />
      </span>
    </div>
  );
};

export default AttachmentIcon;
