import { useNavigate } from "react-router-dom";

export const INITIAL_STATE = {
  filterData: {
    filterKeys: {},
    filterData: [],
  },
};
const Logic = (dispatch, state) => {
  return { actions: {} };
};

export default Logic;
