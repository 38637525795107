import React, { useMemo } from "react";
import TableLayout from "../../components/general/table-layout/TableLayout";

import Card from "../../components/general/card/Card";
import NumberWithLabel from "../../components/general/number-with-label/NumberWithLabel";
import TotalContainer from "../../components/general/total-container/TotalContainer";
import useLogic from "../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import Styles from "./Subscriptions.module.css";
import { SubscriptionsColumn } from "./SubscriptionsColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Subscriptions = () => {
  // request  data

  const { state, updateState, data, setData, error, actions, navigate } =
    useLogic({
      INITIAL_STATE,
      Logic,
    });

  let columns = useMemo(() => SubscriptionsColumn(actions()));
  const filterData = [
    {
      key: "companies.name",
      label: "Company",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "plans.name",
      label: "Plan",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "subscriptions.status",
      label: "Status",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "subscriptions.payment_status",
      label: "Payment Status",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "subscriptions.number_of_users",
      label: "Users",
      sign: [{ value: "is_equal", label: "Equals" }],
    },
    {
      key: "subscriptions.subscription_end_date",
      label: "Expiry Date",
      sign: [{ value: "is_equal", label: "Equals" }],
    },

    // ... other filters
  ];

  const filterKeys = {
    "companies.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "plans.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "subscriptions.payment_status": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "subscriptions.status": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "subscriptions.number_of_users": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "is_not_equal", label: "Not Equals" },
        { value: "is_between", label: "Is Between" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
    "subscriptions.subscription_end_date": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "is_between", label: "Is Between" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
  };

  return (
    <>
      {/* <div className={Styles.title}>
        <Title title={`${"Subscribtions"}(${data.total})`}></Title>
      </div> */}
      <Card style={{ padding: "0 10px", marginBottom: "20px" }}>
        <NumberWithLabel
          label="Expiry Summary"
          textConatinerClassname={Styles.textConatiner}
          contentClassname={Styles.content}
        >
          <TotalContainer
            data={
              Array.isArray(state?.inquiriesAndtickets)
                ? state.inquiriesAndtickets
                : []
            }
            containerTotalStyle={{
              padding: "10px",
              fontSize: "17px",
            }}
            className={Styles.totalContainer}
            textStyle={Styles.textContainer}
          />
        </NumberWithLabel>
      </Card>

      <TableLayout
        tableColumns={columns}
        title={`${"Subscribtions"}(${data.total})`}
        setData={setData}
        data={data}
        error={error}
        showPagination={true}
        filterData={{ filterData: filterData, filterKeys: filterKeys }}
        showFilter={true}
        showButton={true}
        buttonValue={"New"}
        buttonIcon={
          <FontAwesomeIcon
            icon={faPlus}
            style={{ marginRight: "5px", marginLeft: "5px" }}
          />
        }
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        buttonOnClick={() => navigate("/add-subscription")}
      />
    </>
  );
};
export default Subscriptions;
