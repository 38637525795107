import Card from "../../components/general/card/Card";
import Title from "../../components/general/title/Title";
import styles from "./Dashboard.module.css";
import DoughnutChart from "../../components/general/doughnut-chart/DoughnutChart";
import BarChart from "../../components/general/bar-chart/BarChart";
import NumberWithLabel from "../../components/general/number-with-label/NumberWithLabel";
import UseAppTable from "../../helpers/hooks/use-app-table";
import { fetchPosts } from "../../api/services/UserServices";
import { useEffect, useMemo } from "react";
import { Columns } from "./columns";
import Table from "../../components/general/app-table/AppTable";
import Logic, { INITIAL_STATE } from "./logic";
import useLogic from "../../helpers/hooks/use-logic";
import Chart from "react-apexcharts";

const Dashboard = () => {
  const { state, updateState, actions, data, error, columns, getCompanies, getAllSubscriptions, getUserCounts } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const { setData } = actions;
  useEffect(() => {
    getUserCounts();
    getCompanies();
    getAllSubscriptions();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Title title="Dashboard" />
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <Card style={{ padding: "20px 10px" }}>
          
          {/* Totla Customers */}
          <NumberWithLabel
            className={styles.statisticsContainer}
            number={state.customerLoading==true ?(<h5>Loading...</h5>):(state.totalCustomers)}
            label="Total Customers"
            contentClassname={styles.contentClassname}
            textConatinerClassname={styles.textConatinerClassname}
            numberClassname={styles.numberClassname}
            labelClassname={styles.labelClassname}
          >
            {/* Chart */}
            {state.chartData.length > 0 && state.chartData.map((data, index) => (
              <div className={styles.statisticsSubContainer} key={index}>
                <DoughnutChart
                  chartData={data}
                  legend={false}
                  title={true}
                  titleText={data.labels[0]}
                  titleStyle={{ color: "black", fontSize: 17, weight: 100 }}
                  labelFontSize={"30px"}
                />
              </div>
            ))}
            
          </NumberWithLabel>
          {/* Totla Revenue Number */}
          <NumberWithLabel
            className={styles.statisticsContainer}
            number={state.totalEstimatedRevenue}
            label="Estimated Revenue"
            contentClassname={styles.contentClassname}
            textConatinerClassname={styles.textConatinerClassname}
            numberClassname={styles.numberClassname}
            labelClassname={styles.labelClassname}
          >
            {/* Revenue Number */}
            {state.chartData.length > 0 && state.chartData.map((data, index) => (
              <div className={styles.statisticsSubContainer} key={index}>
                <div>{data.datasets[0].data[0]}</div>
              </div>
            ))}
          </NumberWithLabel>
        </Card>
        {/* Table of Upcoming Renewals */}
        <div className={styles.statisticsContainer}>
          <Card className={styles.statisticsCard}>
            <Title
              title={`Upcoming Renewals (${data.total}) `}
              titleStyle={styles.tableTitle}
            ></Title>

            <Table
              columns={columns}
              data={data}
              setData={setData}
              error={error}
            />
          </Card>
          <div style={{ width: 15 }}></div>
          <Card  className={styles.statisticsCard}>
            {state.usersLoading==true ?(
              <div>
                <h3>Loading....</h3>
              </div>
            ):(


            <NumberWithLabel
              className={styles.statisticsContainer}
              number={state.totalUsers}
              label="Users"
              contentClassname={styles.usersContentClassname}
              textConatinerClassname={styles.textConatinerClassname}
              numberClassname={styles.numberClassname}
              labelClassname={styles.labelClassname}
            >
              {console.log("BarChart:  ",state.BarChart)}
              <div style={{ flex: 1, height: "38vh" }}>
              <Chart
                options={state.BarChart.options}
                series={state.BarChart.series}
                type="bar"
              />
              </div>
            </NumberWithLabel>
            )}
           
          </Card>
        </div>
      </Card>
    </div>
  );
};
export default Dashboard;
