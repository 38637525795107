import { $axios, $securedAxios, securePost, unsecurePost } from "../axios";

export const login = (onSuccess, onError, onFinally, payload) => {
    unsecurePost(
        `login/po`,
        { username: payload.username, password: payload.password },
        onSuccess,
        onError,
        onFinally
    );
};

export const fetchUserCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios.post('/company/users/dist',payload)
    .then((response)=>{
        onSuccess(response)
    })
    .catch((error)=>{
        onError(error)
    })
    .finally(()=>{
        onFinally();
    })
}

export const fetchPosts = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/subscriptions/upcoming/renewal?page=${payload.page}`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchfilterAssetHistory = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .get(`/admin/asset-histories/get/filtercolumns`)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const resetPassword = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/password/reset/${payload.token}`, {
            email: payload.email,
            password: payload.password,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const fetchLoggedinUser = (onSuccess, onError, onFinally) => {
    $securedAxios
        .get(`/loggedin/userinfo`)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
