import AppInput from "../../app-input/AppInput";
import AppSelect from "../../app-select/AppSelect";
import Label from "../../label/Label";
import Styles from "./FilterType.module.css";

const FilterType = ({
  type,
  sign,
  setFilterValue,
  dropdown,
  index,
  setFilterInput,
  filterInput,
}) => {
  return (
    <>
      {sign != "is_not_set" && sign != "is_set" ? (
        type === "string" || type === "num" ? (
          <Label
            name="Filter Value"
            className={Styles.label}
            classText={Styles.labelText}
            style={{ flex: 2, margin: "8px 5px" }}
          >
            <AppInput
              type="text"
              onChange={(e) => {
                setFilterValue({ value: e.target.value }, index);
              }}
              containerStyle={{
                padding: "0px 2px",
                margin: "0 5px",
                width: "auto",
                flex: 2,
                borderRadius: 6,
                height: "100%",
              }}
              style={{
                margin: "1px",
                padding: 0,
              }}
            />
          </Label>
        ) : type === "dropdown" ? (
          <Label
            name="Filter Value"
            className={Styles.label}
            classText={Styles.labelText}
            style={{ flex: 2, margin: "8px 5px" }}
          >
            <AppSelect
              styles={{
                dropdownIndicator: (provided, state) => {
                  return {
                    ...provided,
                    color: "var(--primary)",
                    fontSize: 12,
                  };
                },
                valueContainer: (provided, state) => {
                  return { ...provided, padding: "0px" };
                },
              }}
              onChange={(e) => {
                const newFilterInput = [
                  ...filterInput.slice(0, index),
                  {
                    ...filterInput[index],
                    value: e.value,
                    dropdownLabel: e.label,
                  },
                  ...filterInput.slice(index + 1),
                ];
                setFilterInput(newFilterInput);
              }}
              options={dropdown.map((element) => ({
                value: element.value,
                label: element.label,
              }))}
            />
          </Label>
        ) : type === "date" ? (
          <Label
            name="Filter Value"
            className={Styles.label}
            classText={Styles.labelText}
            style={{ flex: 2, margin: "8px 5px" }}
          >
            <AppInput
              type="date"
              onChange={(e) => {
                setFilterValue({ value: e.target.value }, index);
              }}
              containerStyle={{
                padding: "0px 2px",
                margin: "0 5px",
                width: "auto",
                flex: 2,
                borderRadius: 6,
                height: "100%",
              }}
              style={{
                margin: "1px",
                padding: 0,
              }}
            />
          </Label>
        ) : null
      ) : null}
      {sign === "is_between" ? (
        <Label
          name="Filter Value 2"
          className={Styles.label}
          classText={Styles.labelText}
          style={{ flex: 2, margin: "8px 5px" }}
        >
          <AppInput
            type={type === "date" ? "date" : "text"}
            onChange={(e) => {
              setFilterValue({ value2: e.target.value }, index);
            }}
            containerStyle={{
              padding: "0px 2px",
              margin: "0 5px",
              width: "auto",
              flex: 2,
              borderRadius: 6,
              height: "100%",
            }}
            style={{
              margin: "1px",
              padding: 0,
            }}
          />
        </Label>
      ) : null}
    </>
  );
};

export default FilterType;
