import classes from "./Label.module.css";

const Label = ({
  children,
  name,
  classText,
  className,
  titleChildren,
  ...rest
}) => {
  return (
    <div className={`${classes.label} ${className}`} {...rest}>
      {name && (
        <div className={`${classes.name} ${classText}`}>
          {name} {titleChildren && titleChildren}
        </div>
      )}
      {children}
    </div>
  );
};

export default Label;
