import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter,
    useAsyncDebounce,
} from "react-table";
import BTable from "react-bootstrap/Table";
import styles from "./AppTable.module.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import TablePagination from "./TablePagination";
// import { useEffectSkipFirst } from "../../../helpers/Common";

const manualPageSize = [];

const AppTable = ({
    columns,
    data,
    showPagination = true,
    showTotal,
    tbodyStyle,
    containerStyle,
    setData,
    error,
}) => {
    const { results, total, queryPageIndex, params, perPage, loading } = data;
    const totalPageCount = Math.ceil(total / perPage);

    // Use the state and functions returned from useTable to build your UI
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200, // maxWidth is only used as a limit for resizing
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageSize, pageIndex },
    } = useTable(
        {
            columns,
            data: results,
            defaultColumn,
            useControlledState: (state) => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageIndex: params.page,
                    }),
                    [state, params.page]
                );
            },
            initialState: {
                pageIndex: params.page,
                hiddenColumns: columns
                    .filter((col) => col.show === false)
                    .map((col) => col.accessor),
            }, // Pass our hoisted table state
            manualPagination: true,
            pageCount: total ? totalPageCount : null,
            // autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
        },
        // useSortBy,
        useFlexLayout,
        // useFilters,
        usePagination
    );
    // useEffectSkipFirst(() => {
    //   if (rows.length == 0) {
    //     setData((prevState) => {
    //       return {
    //         ...prevState,
    //         params: {
    //           ...prevState.params,
    //           page: prevState.params.page - 1,
    //         },
    //       };
    //     });
    //   }
    // }, [rows]);

    // Render the UI for your table
    return (
        <>
            <div id={styles.appTable} style={containerStyle}>
                <BTable borderless striped hover size="sm" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps([
                                            {
                                                className: column.className,
                                                style: column.style,
                                            },
                                        ])}
                                    >
                                        {column.render("Header")}
                                        {/* Render the columns filter UI */}
                                        {column.filterable ? column.render("Filter") : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} style={tbodyStyle}>
                        {loading ? (
                            <tr className={styles.noResultContainer}>
                                <td>Loading ...</td>
                            </tr>
                        ) : error ? (
                            <tr className={styles.noResultContainer}>
                                <td>{error}</td>
                            </tr>
                        ) : (
                            (rows.length > 0 &&
                                rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })) || (
                                <tr className={styles.noResultContainer}>
                                    <td>No Results</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </BTable>
            </div>
            {showPagination && (
                <div className={styles.pagination}>
                    <div className={styles.tabletotalCount}>Total : {total}</div>
                    {rows.length > 0 && (
                        <TablePagination
                            page={page}
                            gotoPage={(page) => {
                                setData((prevState) => {
                                    return {
                                        ...prevState,
                                        params: {
                                            ...prevState.params,
                                            page,
                                        },
                                    };
                                });
                            }}
                            previousPage={() => {
                                setData((prevState) => {
                                    return {
                                        ...prevState,
                                        params: {
                                            ...prevState.params,
                                            page:
                                                prevState.params.page === 0
                                                    ? 0
                                                    : prevState.params.page - 1,
                                        },
                                    };
                                });
                            }}
                            nextPage={() => {
                                setData((prevState) => {
                                    return {
                                        ...prevState,
                                        params: {
                                            ...prevState.params,
                                            page: prevState.params.page + 1,
                                        },
                                    };
                                });
                            }}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageSize={perPage}
                            pageIndex={pageIndex}
                            setPageSize={setPageSize}
                            manualPageSize={manualPageSize}
                            dataLength={total}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AppTable;
