import { registerNewCustomer } from "../../../../api/services/SubcriptionServices";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { ERROR, SUCCESS } from "../../../../components/general/app-toast/AppToast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
export const INITIAL_STATE = {
    loading: false,
    customer: {
        companyName: "",
        name: "",
        email: "",
        address: "",
        commercial_reg: "",
        tax_reg: "",
        description: "",
        mobile: "",
        password: "",
        sector: "",
        num_of_employee: "",
        industry: "",
        website: "",
        jobTitle: "",
        whatsapp: "",
    },
};
const Logic = (dispatch, state, prop) => {
    const { addToast } = UseAppToast();
    const addCustomer = () => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "loading", value: true }],
        });
        const { customer } = state;
        let params = {
            company_name: customer.companyName,
            description: customer.description,
            name: customer.name,
            email: customer.email,
            password: customer.password,
        };
        debugger;
        registerNewCustomer(
            params,
            (res) => {
                debugger;
                prop.hide();
                addToast({
                    type: SUCCESS,
                    description: res?.data?.message,
                    title: "Success",
                });
            },
            (err) => {
                debugger;
                addToast({
                    type: ERROR,
                    description: err?.response?.data?.message,
                    title: "Success",
                });
            },
            (f) => {
                debugger;
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "loading", value: false }],
                });
            }
        );
    };
    return {
        actions: { addCustomer },
    };
};

export default Logic;
