import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllSubscriptions,
  getSubscriptionSummary,
  updateSubscriptions,
} from "../../api/services/SubcriptionServices";
import { ERROR } from "../../components/general/app-toast/AppToast";
import UseAppTable from "../../helpers/hooks/use-app-table";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
export const INITIAL_STATE = {
  filterData: {
    filterKeys: {},
    filterData: [],
  },
  inquiriesAndtickets: [],
  ticketsLoading: false,
};
const Logic = (dispatch, state) => {
  const { addToast } = UseAppToast();
  let navigate = useNavigate();

  const transformApiResponse = (apiResponse) => {
    return [
      {
        status: [
          { label: "Expired", number: apiResponse.expired_subscriptions },
          { label: "In 7 Days", number: apiResponse.expiring_in_7_days },
          { label: "In 30 Days", number: apiResponse.expiring_in_30_days },
          { label: "In 60 Days", number: apiResponse.expiring_in_60_days },
          { label: "In 90 Days", number: apiResponse.expiring_in_90_days },
        ],
      },
    ];
  };
  const { data, setData, error } = UseAppTable({
    fetchTableData: getAllSubscriptions,
  });
  const actions = () => {
    return {
      navigate: navigate,
      updateStatus: updateStatus,
      updatePaymentStatus: updatePaymentStatus,
    };
  };
  const updateStatus = (updatedData) => {
    updateSubscriptions(
      (res) => {
        addToast({
          type: "success",
          description: "Status Updated Successfully.",
          title: "Success",
        });

        setData((prevData) => {
          const t = prevData["results"].map((item) =>
            item.id === updatedData.id
              ? {
                  ...updatedData,
                  status: res.data.response.status,
                  payment_status: res.data.response.payment_status,
                }
              : item
          );
          return {
            ...prevData,
            results: t,
          };
        });
      },
      (error) => {
        console.log(error);
        addToast({
          type: ERROR,
          description: error.message,
          title: "Error",
        });
      },
      () => {},
      // {
      updatedData
      // status: status,
      // }
    );
  };
  const updatePaymentStatus = (updatedData) => {
    updateSubscriptions(
      (res) => {
        setData((prevData) => {
          const t = prevData["results"].map((item) =>
            item.id === updatedData.id
              ? {
                  ...updatedData,
                  status: res.data.response.status,
                  payment_status: res.data.response.payment_status,
                }
              : item
          );
          return {
            ...prevData,
            results: t,
          };
        });
        addToast({
          type: "success",
          description: "Payment Status Updated Successfully.",
          title: "Success",
        });
      },
      (error) => {
        console.log(error);
        addToast({
          type: ERROR,
          description: error.message,
          title: "Error",
        });
      },
      () => {},
      updatedData
    );
  };
  useEffect(() => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `ticketsLoading`,
          value: true,
        },
      ],
    });
    getSubscriptionSummary(
      (res) => {
        console.log(res.data.response);
        const transformed_res = transformApiResponse(res.data.response);
        console.log(transformed_res);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `inquiriesAndtickets`,
              value: transformed_res,
            },
          ],
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: error.message,
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketsLoading`,
              value: false,
            },
          ],
        });
      }
    );
  }, []);
  return { actions, data, setData, error, navigate };
};

export default Logic;
