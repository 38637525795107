import { Spinner } from "react-bootstrap";
import Styles from "./LoadingSpinner.module.css";
const LoadingSpinner = ({
  isLoading,
  error,
  children,
  isOpacity,
  ...props
}) => {
  return (
    <>
      {isLoading ? (
        <>
          <div className={Styles.container} {...props}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          {isOpacity && children}
        </>
      ) : error ? (
        <div className={Styles.container}>{error}</div>
      ) : (
        children
      )}
    </>
  );
};
export default LoadingSpinner;
