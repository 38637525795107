import { useRef } from "react";
import styles from "./ValidationProvider.module.css";

const ValidationProvider = ({ render, error }) => {
    const requiredTitle = (title) => {
        return (
            <div>
                <label
                    style={{
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    *
                </label>
                {title}
            </div>
        );
    };
    return (
        <div className={styles.container}>
            {render({ requiredTitle })}
            {error.dirty && error.error ? (
                <div className={styles.error}>{error.message}</div>
            ) : null}
        </div>
    );
};
export default ValidationProvider;
