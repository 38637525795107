import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import Title from "../../../components/general/title/Title";
import useLogic from "../../../helpers/hooks/use-logic";
import ChatThread from '../chat-thread/ChatThread';
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./TicketDetails.module.css";

const TicketDetails = () => {
  const { state, updateState,handleSendMessage } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Title title={`Ticket Details #${state.ticketDetails.id}`} />
      </div>
      <Card style={{ padding: 20 }}>
        <ul className={styles.wizard}>
          {state.statuses.map((item, index) => (
            <li
              className={
                state.ticketDetails?.activeStatus?.toLowerCase() == item?.name?.toLowerCase()
                  ? styles.selected
                  : null
              }
              key={item.id}
            >
              {item.name}
            </li>
          ))}
        </ul>
        <div className={styles.ticketDetails}>
          <div
            className={`${styles.groupControl} ${styles.ticketDetailsInput} `}
          >
            <Label
              name="Company Name"
              className={styles.label}
              classText={styles.labelText}
            >
              <div className={styles.inputContainer}>
                <span>{state.ticketDetails.companyName}</span>
              </div>
            </Label>
          </div>
          <div
            className={`${styles.groupControl} ${styles.ticketDetailsInput} `}
          >
            <Label
              name="Ticket Date"
              className={styles.label}
              classText={styles.labelText}
            >
              <div className={styles.inputContainer}>
                <span>{state.ticketDetails.ticketDate}</span>
              </div>
            </Label>
          </div>
          <div
            className={`${styles.groupControl} ${styles.ticketDetailsInput} `}
          >
            <Label
              name="Assignee"
              className={styles.label}
              classText={styles.labelText}
            >
              <div className={styles.inputContainer}>
                <span>{state.ticketDetails.assignee}</span>
              </div>
            </Label>
          </div>
        </div>
        <div className={styles.groupControl}>
          <Label name="Issue" className={styles.label}>
            <div className={styles.inputContainer}>
              <span>{state.ticketDetails.issue}</span>
            </div>
          </Label>
        </div>
        <Title
          title="Thread"
          border={true}
          style={{ color: "var(--primary)", fontSize: "16px", marginTop: 30 }}
        />
        <br />
 <ChatThread followup={state.followup} onSendMessage={(message)=>{
  console.log(message)
  handleSendMessage(message)
 }} 
 messagesLoading={state.messagesLoading}/>
      </Card>
    </div>
  );
};
export default TicketDetails;
