import React, { useMemo } from "react";
import AppButton from "../../../components/general/app-button/AppButton";
import AppInput from "../../../components/general/app-input/AppInput";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import TableLayout from "../../../components/general/table-layout/TableLayout";
import Title from "../../../components/general/title/Title";
import Styles from "./CustomerDetails.module.css";
import { SubColumn } from "./SubColumn";
import { UsersColumn } from "./UsersColumn";

import NumberWithLabel from "../../../components/general/number-with-label/NumberWithLabel";
import TotalContainer from "../../../components/general/total-container/TotalContainer";
import useLogic from "../../../helpers/hooks/use-logic";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import Logic, { INITIAL_STATE } from "./logic";

const CustomerDetails = () => {
  const {
    state,
    updateCustomerState,
    saveData,
    actions,
    navigate,
    userData,
    setUserData,
    setSubData,
    userError,
    subError,
    subData,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  let userColumns = useMemo(() => UsersColumn({ actions: { navigate } }));
  let subColumns = useMemo(() => SubColumn({ actions: { navigate } }));

  const filterData = [
    {
      key: "plans.name",
      label: "Plan",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "subscriptions.status",
      label: "Status",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "subscriptions.number_of_users",
      label: "User",
      sign: [{ value: "is_equal", label: "Equals" }],
    },
    {
      key: "subscriptions.subscription_end_date",
      label: "Expiry Date",
      sign: [{ value: "is_equal", label: "Equals" }],
    },

    // ... other filters
  ];
  const filterKeys = {
    "plans.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "subscriptions.status": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "subscriptions.number_of_users": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
    "subscriptions.subscription_end_date": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "is_between", label: "Is Between" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
  };

  const filterData2 = [
    {
      key: "name",
      label: "Name",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "email",
      label: "Email",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "welcome_email",
      label: "Welcome Email Status",
      sign: [{ value: "contains", label: "Contains" }],
    },
    {
      key: "users.created_at",
      label: "Created At",
      sign: [{ value: "is_between", label: "Is Between" }],
    },
    {
      key: "users.updated_at",
      label: "Updated At",
      sign: [{ value: "is_between", label: "Is Between" }],
    },

    // ... other filters
  ];
  const filterKeys2 = {
    name: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    email: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    welcome_email: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: "Contains" },
        { value: "is_equal", label: "Equals" },
        // ... other signs
      ],
    },
    "users.created_at": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "is_not_equal", label: "Not Equals" },
        { value: "is_between", label: "Is Between" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
    "users.updated_at": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: "Equals" },
        { value: "is_not_equal", label: "Not Equals" },
        { value: "is_between", label: "Is Between" },
        { value: "greater_than", label: "Greater Than" },
        { value: "less_than", label: "Less Than" },
        { value: "greater_than_or_equal_to", label: "Greater Than Or Equal" },
        { value: "less_than_or_equal_to", label: "Less Than Or Equal" },

        // ... other signs
      ],
    },
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Title title={state.customer.name}>
          <div className={Styles.titleButton}>
            <AppButton
              className="primaryButton"
              style={{ color: "white" }}
              onClick={saveData}
              value="Save"
            />
            <AppButton
              className="whiteButton"
              onClick={() => navigate("/customers")}
              value="Cancel"
            />
          </div>
        </Title>
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <div className={Styles.formContainer}>
          <div className={Styles.section}>
            <div className={Styles.sectionContainer}>
              <Title title="Company Details" border={true} />
              {!state.customerLoading ? (
                <div
                  style={{
                    // marginTop: "15px",
                    padding: "15px",
                    background: "#f7f7f7",
                  }}
                >
                  <div className={Styles.groupControl}>
                    <Label name="Name" className={Styles.label}>
                      <AppInput
                        type="text"
                        className={Styles.input}
                        onChange={(e) => {
                          updateCustomerState([
                            {
                              type: UPDATE_PROP,
                              prop: `name`,
                              value: e.target.value,
                            },
                          ]);
                        }}
                        value={state.customer.name}
                      />
                    </Label>
                  </div>
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label name="comm Reg" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `commercial_registration`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.commercial_registration}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Tax Card" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `tax_registration`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.tax_registration}
                        />
                      </Label>
                    </div>
                  </div>
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label
                        name="Number of Employees"
                        className={Styles.label}
                      >
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `number_of_employees`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.number_of_employees}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Sector" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `sector`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.sector}
                        />
                      </Label>
                    </div>
                  </div>
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label name="Industry" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `industry`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.industry}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Active Users" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `users_count`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.activeUsers}
                        />
                      </Label>
                    </div>
                  </div>

                  <div className={Styles.groupControl}>
                    <Label name="Address" className={Styles.label}>
                      <AppInput
                        onChange={() => {}}
                        renderProp={({ onChange }) => (
                          <textarea
                            onChange={(e) => {
                              updateCustomerState([
                                {
                                  type: UPDATE_PROP,
                                  prop: `address`,
                                  value: e.target.value,
                                },
                              ]);
                            }}
                            value={state.customer.address}
                            className={Styles.input}
                            style={{ minHeight: "73px" }}
                          ></textarea>
                        )}
                      />
                    </Label>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
          <div className={Styles.section}>
            <div className={Styles.sectionContainer}>
              <Title title="Contact Person" border={true} />
              {!state.customerLoading ? (
                <div
                  style={{
                    // marginTop: "15px",
                    padding: "15px",
                    background: "#f7f7f7",
                  }}
                >
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label name="Name" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `name`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.contact_person_name}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Position" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `job_title`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.job_title}
                        />
                      </Label>
                    </div>
                  </div>
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label name="Mobile" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `contact_person_mobile`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.contact_person_mobile}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Email" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `contact_person_email`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.contact_person_email}
                        />
                      </Label>
                    </div>
                  </div>
                  <div className={Styles.groupControl}>
                    <div className={Styles.inputControl}>
                      <Label name="Whatsapp" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `contact_person_whatsapp`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.contact_person_whatsapp}
                        />
                      </Label>
                    </div>
                    <div className={Styles.inputControl}>
                      <Label name="Website" className={Styles.label}>
                        <AppInput
                          type="text"
                          className={Styles.input}
                          onChange={(e) => {
                            updateCustomerState([
                              {
                                type: UPDATE_PROP,
                                prop: `contact_person_website`,
                                value: e.target.value,
                              },
                            ]);
                          }}
                          value={state.customer.contact_person_website}
                        />
                      </Label>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
            <div
              className={Styles.sectionContainer}
              style={{ marginTop: "10px" }}
            >
              <Title title="Inquiries & Tickets" border={true} />
              <div
                style={{
                  padding: "8px",
                  background: "#f7f7f7",
                }}
              >
                <NumberWithLabel
                  number={state.allTicketsTotal}
                  label="Inquiries/Tickets"
                  textConatinerClassname={Styles.textConatiner}
                  contentClassname={Styles.content}
                >
                  <TotalContainer
                    data={
                      Array.isArray(state?.inquiriesAndtickets)
                        ? state.inquiriesAndtickets
                        : []
                    }
                  />
                </NumberWithLabel>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.sectionContainer} style={{ paddingTop: "20px" }}>
          <div className={Styles.title}>
            <Title title="Subscriptions" border={true}></Title>
          </div>
          <div>
            <TableLayout
              tableColumns={subColumns}
              setData={setSubData}
              data={subData}
              error={subError}
              showPagination={true}
              filterData={{ filterData: filterData, filterKeys: filterKeys }}
              showFilter={true}
            />
          </div>
        </div>
        <div className={Styles.sectionContainer} style={{ paddingTop: "20px" }}>
          <div className={Styles.title}>
            <Title title="Users" border={true}></Title>
          </div>
          <div>
            <TableLayout
              tableColumns={userColumns}
              setData={setUserData}
              data={userData}
              error={userError}
              showPagination={true}
              filterData={{ filterData: filterData2, filterKeys: filterKeys2 }}
              showFilter={true}
            />
          </div>
        </div>
        {/* <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              value="Save"
            />
            <AppButton
              classes="whiteButton"
              value="Cancel"
            />
          </div> */}
      </Card>
    </div>
  );
};
export default CustomerDetails;
