import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const NavLinks = [
  {
    name: "Dashboard",
    to: "dashboard",
    icon: <FontAwesomeIcon icon={faClipboardList} />,
  },
  {
    name: "Customers",
    to: "/customers",
    icon: <FontAwesomeIcon icon={faUsers} />,
  },
  {
    name: "Subscriptions",
    to: "/subscriptions",
    icon: <FontAwesomeIcon icon={faCogs} />,
  },
  {
    name: "Inquiries/Support",
    to: "/tickets",
    icon: <FontAwesomeIcon icon={faCogs} />,
  },
  {
    name: "System Logs",
    to: "/systemlogs",
    icon: <FontAwesomeIcon icon={faCogs} />,
  },
  // {
  //   name: "Master Data",
  //   to: "/master-data",
  //   icon: <FontAwesomeIcon icon={faCogs} />,
  //   items: [
  //     {
  //       name: "Departments",
  //       to: "departments",
  //     },
  //     {
  //       name: "Locations",
  //       to: "locations",
  //     },
  //     {
  //       name: "Forms/Checklists",
  //       to: "forms-checklists",
  //     },
  //   ],
  //   // suffix: (
  //   //   <p style={{ backgroundColor: "green", borderRadius: 30, padding: 5 }}>
  //   //     10
  //   //   </p>
  //   // ),
  // },
  // {
  //   name: "Users",
  //   to: "users",
  //   icon: <FontAwesomeIcon icon={faUsers} />,
  // },
  // {
  //   name: "Schedule",
  //   to: "schedule",
  //   icon: <FontAwesomeIcon icon={faCalendarAlt} />,
  // },

  // {
  //   name: "Advanced",
  //   to: "/advanced",
  //   icon: <FontAwesomeIcon icon={faCog} />,
  //   items: [
  //     {
  //       name: "Permissions",
  //       to: "permissions",
  //     },
  //     {
  //       name: "Roles",
  //       to: "roles",
  //     },
  //     {
  //       name: "Actions",
  //       to: "actions",
  //     },
  //     {
  //       name: "Email Templates",
  //       to: "email-templates",
  //     },
  //   ],
  //   // suffix: (
  //   //   <p style={{ backgroundColor: "green", borderRadius: 30, padding: 5 }}>
  //   //     10
  //   //   </p>
  //   // ),
  // },
];

export default NavLinks;
