import { useNavigate, useParams } from "react-router-dom";

import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { useEffect, useLayoutEffect } from "react";
import {
  getAllCompanies,
  getAllPlans,
  getCompany,
  getPlanPricing,
  getSubscription,
  getSubscriptionForSpecificCompany,
  storeSubscription,
  updateSubscriptions,
} from "../../../api/services/SubcriptionServices";
import { UPDATE_PROP } from "../../../store/ActionTypes";
export const INITIAL_STATE = {
  companies: [],
  plans: [],
  planPricing: [],
  status: [
    { label: "Active", value: "active" },
    { label: "Pending", value: "pending" },
    {
      label: "Closed",
      value: "closed",
    },

    {
      label: "Expired",
      value: "expired",
    },
  ],

  paymentStatus: [
    { label: "Paid", value: "paid" },
    { label: "Pending", value: "pending" },
  ],
  form: {
    selectedCompany: null,
    selectedPlan: null,
    selectedPricing: null,
    status: null,
    paymentStatus: null,
    startDate: "",
    endDate: "",
    users: "",
  },
  saveLoading: false,
  pageLoading: false,
  error: null,
};
const Logic = (dispatch, state) => {
  let navigate = useNavigate();
  const { addToast } = UseAppToast();
  const { id } = useParams();
  // console.log("id", id);
  const handleUpdateOrCreate = () => {
    console.log("state", state.form);
    let payload = {
      company_id: state.form.selectedCompany.value,
      plan_id: state.form.selectedPricing.plan_id,
      plan_pricing_id: state.form.selectedPricing.plan_pricing_id,
      status: state.form.status.value,
      subscription_start_date: state.form.startDate,
      subscription_end_date: state.form.endDate,
      payment_status: state.form.paymentStatus.value,
      number_of_users: state.form.users,
    };
    if (id) {
      payload.id = id;
    }

    console.log("payload", payload);

    storeSubscription(
      (res) => {
        console.log("response", res.data.response);
        addToast({
          type: "success",
          description: "Subscription adding successfully",
          title: "Success",
        });
        navigate("/subscriptions");
      },
      (error) => {
        addToast({
          type: "error",
          description: error.response.data.message,
          title: "Error",
        });
      },
      () => {
        // dispatch({
        //   type: UPDATE_PROP,
        //   prop: "saveLoading",
        //   value: false,
        // });
      },
      { ...payload }
    );
  };
  useLayoutEffect(() => {
    // get companies
    getAllCompanies(
      (res) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: "companies",
              value: res.data.response.data,
            },
          ],
        });
      },
      (error) => {
        addToast({
          type: "error",
          description: "Error fetching companies",
          title: "Error",
        });
      },
      () => {
        // setLoading(false);
      },
      {}
    );

    getAllPlans(
      (res) => {
        // console.log("plans", res.data.response.data);
        //   [
        //     {
        //         "name": "Monthly",
        //         "factor": 1,
        //         "plans": [
        //             {
        //                 "name": "Standard",
        //                 "price": 13.99,
        //                 "plan_id": 2,
        //                 "plan_pricing_id": 2,
        //                 "featuresList": [
        //                     "Access the mobile app for easy audit submission.",
        //                     "Access the web portal for seamless administration.",
        //                     "Manage user accounts effortlessly.",
        //                     "Design dynamic audits with ease.",
        //                     "Schedule audits with just a few clicks.",
        //                     "Create actionable plans to drive success.",
        //                     "Enjoy round-the-clock platform support.",
        //                     "Secure your platform with roles and permissions.",
        //                     "Gain real-time insights for smarter decisions"
        //                 ]
        //             },
        //             {
        //                 "name": "Advanced",
        //                 "price": 16.99,
        //                 "plan_id": 3,
        //                 "plan_pricing_id": 3,
        //                 "featuresList": [
        //                     "All Starter Plan Features",
        //                     "Enjoy premium platform support (48 hrs).",
        //                     "Secure your platform with roles and permissions.",
        //                     "Gain real-time insights for smarter decisions"
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         "name": "Yearly",
        //         "factor": 12,
        //         "plans": [
        //             {
        //                 "name": "Standard",
        //                 "price": 9.99,
        //                 "plan_id": 2,
        //                 "plan_pricing_id": 4,
        //                 "featuresList": [
        //                     "Access the mobile app for easy audit submission.",
        //                     "Access the web portal for seamless administration.",
        //                     "Manage user accounts effortlessly.",
        //                     "Design dynamic audits with ease.",
        //                     "Schedule audits with just a few clicks.",
        //                     "Create actionable plans to drive success.",
        //                     "Enjoy round-the-clock platform support.",
        //                     "Secure your platform with roles and permissions.",
        //                     "Gain real-time insights for smarter decisions"
        //                 ]
        //             },
        //             {
        //                 "name": "Advanced",
        //                 "price": 11.99,
        //                 "plan_id": 3,
        //                 "plan_pricing_id": 5,
        //                 "featuresList": [
        //                     "All Starter Plan Features",
        //                     "Enjoy premium platform support (48 hrs).",
        //                     "Secure your platform with roles and permissions.",
        //                     "Gain real-time insights for smarter decisions"
        //                 ]
        //             }
        //         ]
        //     }
        // ]
        // concatenate the name, and name of the plans, with each plan having a price and plan_pricing_id
        // Monthly - Standard
        // Monthly - Advanced
        // Yearly - Standard
        // Yearly - Advanced

        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: "plans",
              value: res.data.response.data,
            },
          ],
        });
      },
      (error) => {
        addToast({
          type: "error",
          description: "Error fetching plans",
          title: "Error",
        });
      },
      () => {
        // setLoading(false);
      },
      {}
    );

    getPlanPricing(
      (res) => {
        // console.log("pricing", res.data.response.data);
        let pricing = res.data.response.data
          .map((item) => {
            return item.plans.map((plan) => {
              return {
                name: item.name + " - " + plan.name,
                price: plan.price,
                plan_id: plan.plan_id,
                plan_pricing_id: plan.plan_pricing_id,
                featuresList: plan.featuresList,
              };
            });
          })
          .flat();
        // ad increasing ids
        pricing = pricing.map((item, index) => {
          return { ...item, id: index + 1 };
        });
        console.log("pricing", pricing);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: "planPricing",
              value: pricing,
            },
          ],
        });
      },
      (error) => {
        addToast({
          type: "error",
          description: "Error fetching pricing",
          title: "Error",
        });
      },
      () => {
        // setLoading(false);
      },
      {}
    );
    if (id) {
      getSubscription(
        (res) => {
          let data = res.data.response;
          console.log("subscription");
          console.log(state.companies);
          dispatch({
            payload: [
              {
                type: UPDATE_PROP,
                prop: "subscription",
                value: data,
              },
            ],
          });
        },
        (error) => {
          addToast({
            type: "error",
            description: "Error fetching subscription",
            title: "Error",
          });
        },
        () => {
          // setLoading(false);
        },
        { id: id }
      );
    }
  }, []);

  useEffect(() => {
    if (
      id &&
      state?.subscription &&
      state.companies?.length > 0 &&
      state.planPricing?.length > 0
    ) {
      const selectedCompany = state.companies.find(
        (item) => item.id === state.subscription.company_id
      );

      // selected pricing
      const selectedPricing = state.planPricing.find(
        (item) =>
          item.plan_pricing_id == state.subscription.plan_pricing_id &&
          item.plan_id == state.subscription.plan_id
      );

      console.log("selectedCompany", state.subscription);
      // update selected items
      dispatch({
        payload: [
          {
            type: UPDATE_PROP,
            prop: "form",
            value: {
              ...state.form,
              selectedCompany: {
                label: selectedCompany.name,
                value: selectedCompany.id,
              },

              selectedPricing: {
                label: selectedPricing.name,
                value: selectedPricing.id,
                plan_id: selectedPricing.plan_id,
                plan_pricing_id: selectedPricing.plan_pricing_id,
              },
              status: state.status.find(
                (item) => item.value === state.subscription.status
              ),
              paymentStatus: state.paymentStatus.find(
                (item) => item.value === state.subscription.payment_status
              ),
              startDate: state.subscription.subscription_start_date,
              endDate: state.subscription.subscription_end_date,
              users: state.subscription.number_of_users,
            },
          },
        ],
      });
    }
  }, [id, state.companies, state.planPricing, state.subscription]);
  return {
    state,
    handleUpdateOrCreate,
    navigate,
  };
};

export default Logic;
