import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./en.json";
import translationAR from "./ar.json";
import i18next from "i18next";
import { LANGUAGE } from "../helpers/constants/StaticKeys";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    // lng: "ar",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const toggleLanguage = (lng) => {
  if (lng == "ar") {
    i18next.changeLanguage("ar");
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    localStorage.setItem(LANGUAGE, "ar");
  } else {
    i18next.changeLanguage("en");
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    localStorage.setItem(LANGUAGE, "en");
  }
};

export default i18n;
