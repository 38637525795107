import { UPDATE_PROP, MERGE_PROP, DELETE_PROP } from "../../../store/ActionTypes";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getCompany,
    getCompanyUser,
    updateCompany,
    getSubscriptionForSpecificCompany,
} from "../../../api/services/SubcriptionServices";
import { ERROR, SUCCESS } from "../../../components/general/app-toast/AppToast";
import {
    ticketsSummeryForSpecificCompany
  } from "../../../api/services/TicketsServices";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import UseAppTable from "../../../helpers/hooks/use-app-table";
export const INITIAL_STATE = {
    customer: {
        // companyDetails: {
        //     name: "company",
        //     commReg: "comment",
        //     taxCard: "test",
        //     numberOfEmployee: "test",
        //     sector: "test",
        //     industry: "test",
        //     activeUsers: "test",
        //     addresses: "address",
        // },
        // contactPerson: {
        //     name: "mohamed",
        //     position: "2",
        //     mobile: "0133233",
        //     email: "email@email.com",
        //     whatsapp: "0321313",
        //     website: "website.com",
        // },
    },
    companyUsers: [],
    filterData: {
        filterKeys: {},
        filterData: [],
    },
    inquiriesAndtickets: {},
    allTicketsTotal: "",
    ticketsLoading: false,
};

const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const location = useLocation();
    let customerId = location.state.customerId;
    const { addToast } = UseAppToast();
    useEffect(() => {
        if (customerId == undefined) {
            navigate("/customers");
        }
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "customerLoading",
                    value: true,
                },
                {
                    type: UPDATE_PROP,
                    prop: "usersLoading",
                    value: true,
                },
                {
                    type: UPDATE_PROP,
                    prop: "customerId",
                    value: customerId,
                },
            ],
        });
        getCompany(
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "customer",
                            value: response.data.response.data[0],
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: "error",
                    description: error.response.data.message || error.message,
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "customerLoading",
                            value: false,
                        },
                    ],
                });
            },
            customerId
        );
        // getCompanyUser(
        //     (response) => {
        //         debugger;
        //         dispatch({
        //             payload: [
        //                 {
        //                     type: UPDATE_PROP,
        //                     prop: "companyUsers",
        //                     value: response.data.response.data,
        //                 },
        //             ],
        //         });
        //     },
        //     (error) => {
        //         addToast({
        //             type: "error",
        //             description: error.response.data.message || error.message,
        //             title: "Error",
        //         });
        //     },
        //     () => {
        //         dispatch({
        //             payload: [
        //                 {
        //                     type: UPDATE_PROP,
        //                     prop: "usersLoading",
        //                     value: false,
        //                 },
        //             ],
        //         });
        //     },
        //     customerId
        // );
        ticketsSummeryForSpecificCompany(
            (res) => {
                console.log(res);
                const transformed_res = transformApiResponse(res);
                console.log(transformed_res);
                dispatch({
                  payload: [
                    {
                      type: UPDATE_PROP,
                      prop: `inquiriesAndtickets`,
                      value: transformed_res,
                    },
                    {
                      type: UPDATE_PROP,
                      prop: `allTicketsTotal`,
                      value: res.all_tickets?.total,
                    },
                  ],
                });
              },
              (error) => {
                console.log(error);
                dispatch({
                  payload: [
                    {
                      type: UPDATE_PROP,
                      prop: `error`,
                      value: "cannot load this page",
                    },
                  ],
                });
                addToast({
                  type: ERROR,
                  description: error.message,
                  title: "Error",
                });
              },
              () => {
                dispatch({
                  payload: [
                    {
                      type: UPDATE_PROP,
                      prop: `ticketsLoading`,
                      value: false,
                    },
                  ],
                });
              },
            {
                " company_id":customerId 
            }
        )
    }, []);

    const {
        data: userData,
        setData: setUserData,
        error: userError,
    } = UseAppTable({
        fetchTableData: getCompanyUser,
        payload: { customerId },
    });
    const {
        data: subData,
        setData: setSubData,
        error: subError,
    } = UseAppTable({
        fetchTableData: getSubscriptionForSpecificCompany,
        payload: { customerId },
    });
    const transformApiResponse = (response) => {
        const convertStatus = (statusObj) => {
          // Define the desired order and the label changes
          const order = ["total", "open", "in_progress", "feedback", "solved", "closed"];
          const labelMap = {
            total: "Total",
            open: "Open",
            in_progress: "In Progress",
            awaiting_feedback: "Feedback", // Rename awaiting_feedback to Feedback
            solved: "Solved",
            closed: "Closed",
          };
      
          // Create the array in the desired order
          return order.map((key) => ({
            label: labelMap[key] || key, // Use the mapped label or the original key
            number: statusObj[key] !== undefined ? statusObj[key] : 0 // Provide a default value of 0 if undefined
          }));
        };
      
        const transformedData = [
          {
            text: "Support",
            status: convertStatus(response.support)
          },
          {
            text: "Inquiry",
            status: convertStatus(response.inquiry)
          }
        ];
      
        return transformedData;
      };
      
    const updateCustomerState = (optionsPayload) => {
        let payload = [];
        let propPrefix = `customer`;
        optionsPayload.forEach((element) => {
            payload.push({
                type: element.type,
                prop: propPrefix + `.${element.prop}`,
                value: element.value,
            });
        });
        dispatch({ payload });
    };
    const saveData = () => {
        var data= state.customer;
        
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "customerLoading",
                    value: true,
                },
            ],
        });
        // debugger;
        updateCompany(
            (response) => {
                // debugger;
                addToast({
                    type: "success",
                    description: response.message,
                    title: "Success",
                });
            },
            (error) => {
                addToast({
                    type: "error",
                    description: error.response.data.message || error.message,
                    title: "Error",
                });
            },
            (f) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "customerLoading",
                            value: false,
                        },
                    ],
                });
            }, {...data}
        );
    };
    return {
        updateCustomerState,
        saveData,
        navigate,
        userData,
        setUserData,
        setSubData,
        subData,
        userError,
        subError
    };
};
export default Logic;
