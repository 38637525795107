import { Navigate } from "react-router-dom";
import { AUTHENTICATION_TOKEN } from "./constants/StaticKeys";

const ProtectedRoute = ({ children }) => {
    // let isAuthenticated = false;
    let token = localStorage.getItem(AUTHENTICATION_TOKEN);
    // if (token != null) {
    //     isAuthenticated = true;
    // }
    return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
