import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
  Title
);

const areEqual = (prevProps, nextProps) => {
  if (
    JSON.stringify(prevProps.chartData) == JSON.stringify(nextProps.chartData)
  ) {
    return true;
  }
  return false;
};

const BarChart = React.memo(
  ({
    chartData,
    legend = true,
    legendPosition = "right",
    axisBorder = { width: 2, color: "gray" },
    xticksStyle = { color: "black", fontSize: 15 },
    yticksStyle = { color: "black", fontSize: 15 },
    indexAxis = "x",
    title = false,
    titleText = "",
    titleStyle = { color: "black", fontSize: 15 },
    titlePosition = "bottom",
    xStacked = false,
    yStacked = false,
    borderSkipped = "start",
    xTicksDisplay = true,
    yTicksDisplay = true,
    displayGrid = true,
    categoryPercentage = 1,
    barPercentage = 0.5,
  }) => {
    const options = {
      indexAxis: indexAxis,
      responsive: true,
      scales: {
        x: {
          stacked: xStacked,
          grid: {
            borderWidth: axisBorder.width,
            borderColor: axisBorder.color,
            display: displayGrid,
          },
          ticks: {
            display: xTicksDisplay,
            autoSkip: false,
            color: xticksStyle.color,
            font: {
              size: xticksStyle.fontSize,
            },
          },
        },
        y: {
          stacked: yStacked,
          grid: {
            borderWidth: axisBorder.width,
            borderColor: axisBorder.color,
            display: displayGrid,
          },
          ticks: {
            display: yTicksDisplay,
            beginAtZero: true,
            color: yticksStyle.color,
            font: {
              size: yticksStyle.fontSize,
            },
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: legend,
          position: legendPosition,
        },
        title: {
          display: title,
          text: titleText,
          color: titleStyle.color,
          font: { size: titleStyle.fontSize },
          position: titlePosition,
        },
      },
      barPercentage: barPercentage,
      categoryPercentage: categoryPercentage,
      // barThickness: barThickness,
      // maxBarThickness: 10,
      // minBarLength: 2,
      borderRadius: 50,
      borderSkipped: borderSkipped,
    };
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Bar options={options} data={chartData} />
      </div>
    );
  },
  areEqual
);

export default BarChart;
