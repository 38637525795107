import { useEffect } from "react";
import { UPDATE_PROP } from "../../store/ActionTypes";
import CryptoJS from "react-native-crypto-js";
import base64 from "react-native-base64";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../api/services/UserServices";
import { ERROR, SUCCESS } from "../../components/general/app-toast/AppToast";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { ENCRYPT_KEY } from "../../helpers/constants/StaticKeys";
import { encrypted } from "../../helpers/Common";
export const INITIAL_STATE = {
    email: "",
    token: "",
    invalid: false,
    form: {
        password: "",
        confirm_password: "",
        saveLoading: false,
    },
};

const Logic = (dispatch, state) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToast } = UseAppToast();
    const navigate = useNavigate();
    useEffect(() => {
        let key = ENCRYPT_KEY;

        if (searchParams.get("t") && searchParams.get("e")) {
            let token = searchParams.get("t");
            let encryptedEmail = searchParams.get("e");
            let email = "";
            try {
                let encrypted_json = JSON.parse(base64.decode(encryptedEmail));
                console.log(encrypted_json);

                let decrypted = CryptoJS.AES.decrypt(
                    encrypted_json.value,
                    CryptoJS.enc.Base64.parse(key),
                    {
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
                    }
                );
                email = decrypted.toString(CryptoJS.enc.Utf8);
            } catch (error) {
                dispatch({ payload: [{ type: UPDATE_PROP, prop: "invalid", value: true }] });
            }
            dispatch({
                payload: [
                    { type: UPDATE_PROP, prop: "email", value: email },
                    { type: UPDATE_PROP, prop: "token", value: token },
                ],
            });
        } else {
            // set error variable that hides the page
            dispatch({ payload: [{ type: UPDATE_PROP, prop: "invalid", value: true }] });
        }
    }, []);
    const resetHandler = () => {
        const { token, email, form } = state;
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        resetPassword(
            (res) => {
                addToast({
                    type: SUCCESS,
                    description: res.message || "Completed successfully",
                    title: "Success",
                });
                navigate("/login");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: error?.response?.data?.message || "Something went wrong!",
                    title: "Error",
                });
            },
            (f) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: true,
                        },
                    ],
                });
            },
            { token, email, password: encrypted(form.password) }
        );
    };
    return { actions: { resetHandler } };
};

export default Logic;
