import styles from "./ActivateDeactivateModal.module.css";
import Logic, { INITIAL_STATE } from "./logic";
import useValidator from "../../../../helpers/hooks/use-validator";
import Title from "../../../../components/general/title/Title";
import AppInput from "../../../../components/general/app-input/AppInput";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../helpers/hooks/use-logic";
import Label from "../../../../components/general/label/Label";
import AppModal from "../../../../components/general/app-modal/AppModal";
import AppButton from "../../../../components/general/app-button/AppButton";

const ActivateNDeactivateModal = ({ show, hide, targetCompany, setData }) => {
   
  const { state, updateState, updateCustomer } = useLogic({
    INITIAL_STATE,
    Logic,
    prop: { hide, targetCompany,update:setData},
    
  });
  const { loading } = state;
  return (
    <AppModal
      show={show}
      onHide={() => {
        hide();
      }}
      className="modelContent"
      bodyStyle={{ paddingTop: 0 }}
      footerStyle={{ border: 0, paddingTop: 0 }}
      headerStyle={{ border: 0, paddingBottom: 0 }}
    >
      <div className={styles.sectionContainer}>
        <Title
          title={
            targetCompany.status == "Activated"
              ? "Deactivate " + targetCompany.name
              : "Activate " + targetCompany.name
          }
          border={true}
        />
        <div className={styles.groupInputs}>
          {targetCompany.status == "Activated" ? (
            <div className={styles.groupControl}>
              <Label name="Deactivate Reason" className={styles.label}>
                <AppInput
                  type="text"
                  className={styles.input}
                  onChange={(e) =>
                    updateState({ prop: "reason", value: e.target.value })
                  }
                  value={state.reason}
                  textarea
                />
              </Label>
            </div>
          ) : (
            <div>Are you sure you want to activate this company?</div>
          )}
        </div>
        <div className={styles.actionButton}>
          <AppButton
            className="primaryButton"
            onClick={() => {
              updateCustomer(
                targetCompany.status == "Activated"
                  ? "Deactivated"
                  : "Activated"
              );
            }}
            //   () => {
            //   addAssignee(state.customer);
            //   set();
            //   setAssigneeValue();
            // }}
            isLoading={loading}
            value="Confirm"
            style={{ marginRight: "10px" }}
            disabled={loading}
          />
          <AppButton
            className="whiteButton"
            onClick={() => {
              hide();
            }}
            value="Cancel"
            style={{ marginRight: "10px" }}
            disabled={loading}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default ActivateNDeactivateModal;
