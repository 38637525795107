import Select, { components } from "react-select";
import { defaultSelectStyles } from "./Constants";
import React, { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AppSelect = ({ onChange, options, defaultValue, ...props }) => {
  const { styles, ...rest } = props;

  // Function to get the default value from the options
  const getDefaultOption = (value) => {
    // console.log(value);
    return options.find((option) => option.value === value) || null;
  };

  const [value, setValue] = useState(getDefaultOption(defaultValue));

  useEffect(() => {
    let d = getDefaultOption(defaultValue);
    console.log(d);
    setValue(d);
  }, [defaultValue]);

  const handleChange = (selectedOption) => {
    setValue(selectedOption);
    onChange(selectedOption);
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={faChevronDown} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Select
      styles={
        styles ? { ...defaultSelectStyles, ...styles } : defaultSelectStyles
      }
      onChange={handleChange}
      options={options}
      value={value}
      components={{ DropdownIndicator }}
      {...rest}
    />
  );
};

export default AppSelect;
