import React, { useMemo, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import UseAppTable from "../../helpers/hooks/use-app-table";
import TableLayout from "../../components/general/table-layout/TableLayout";
import { fetchfilterAssetHistory, fetchPosts } from "../../api/services/UserServices";
import { ERROR } from "../../components/general/app-toast/AppToast";
import { useNavigate } from "react-router-dom";
import { SystemLogsColumn } from "./SystemLogsColumn";
import Styles from "./SystemLogs.module.css";
import Table from "../../components/general/app-table/AppTable";
import Title from "../../components/general/title/Title";
import NumberWithLabel from "../../components/general/number-with-label/NumberWithLabel";
import TotalContainer from "../../components/general/total-container/TotalContainer";
import Card from "../../components/general/card/Card";
import Logic, { INITIAL_STATE } from "./logic";
import useLogic from "../../helpers/hooks/use-logic";

const SystemLogs = () => {
    // request  data

    const { data, setData, error } = UseAppTable({
        fetchTableData: fetchPosts,
    });
    const { state, updateState, actions } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    // useEffect(() => {
    //   fetchfilterAssetHistory(
    //     (res) => {
    //       const newFilterData = res.data.response.reduce(function (map, obj) {
    //         map[obj.key] = obj;
    //         return map;
    //       }, {});
    //       setFilterData((prevState) => {
    //         return {
    //           ...prevState,
    //           filterKeys: newFilterData,
    //           filterData: res.data.response,
    //         };
    //       });
    //     },
    //     (error) => {
    //       addToast({
    //         type: ERROR,
    //         description: error.message,
    //         title: "Error",
    //       });
    //     },
    //     (f) => {}
    //   );
    // }, []);
    let columns = useMemo(() => SystemLogsColumn, []);

    return (
        <>
            <TableLayout
                tableColumns={columns}
                setData={setData}
                title={`System Logs (${data.total})`}
                showButton={true}
                data={data}
                error={error}
            />
        </>
    );
};
export default SystemLogs;
