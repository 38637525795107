import { useEffect, useMemo,useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllTickets,
  fetchTicketsSummery,
  deleteTickets,
} from "../../../api/services/TicketsServices";
import UseAppTable from "../../../helpers/hooks/use-app-table";
import { TicketsColumn } from "./TicketsColumn";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { ERROR, SUCCESS } from "../../../components/general/app-toast/AppToast";
export const INITIAL_STATE = {
  filterData: {
    filterKeys: {},
    filterData: [],
  },
  // inquiriesAndtickets: [
  //   {
  //     text: "inquiries",
  //     status: [
  //       { label: "open", number: 150 },
  //       { label: "in progress", number: 150 },
  //       { label: "open", number: 150 },
  //       { label: "open", number: 150 },
  //       { label: "open", number: 150 },
  //     ],
  //   },
  //   {
  //     text: "tickets",
  //     status: [
  //       { label: "open", number: 150 },
  //       { label: "in progress", number: 150 },
  //       { label: "open", number: 150 },
  //       { label: "open", number: 150 },
  //       { label: "open", number: 150 },
  //     ],
  //   },
  // ],
  inquiriesAndtickets: {},
  allTicketsTotal: "",
  ticketsLoading: false,
};
const Logic = (dispatch, state) => {
  const { data, setData, error } = UseAppTable({
    fetchTableData: getAllTickets,
  });
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const actions = () => {
    return {
      navigate: navigate,
      bulkDelete: bulkDelete,
    };
  };
  // Function to transform the API response
  const transformApiResponse = (response) => {
    const convertStatus = (statusObj) => {
      // Define the desired order and the label changes
      const order = ["total", "open", "in_progress", "feedback", "solved", "closed"];
      const labelMap = {
        total: "Total",
        open: "Open",
        in_progress: "In Progress",
        awaiting_feedback: "Feedback", // Rename awaiting_feedback to Feedback
        solved: "Solved",
        closed: "Closed",
      };
  
      // Create the array in the desired order
      return order.map((key) => ({
        label: labelMap[key] || key, // Use the mapped label or the original key
        number: statusObj[key] !== undefined ? statusObj[key] : 0 // Provide a default value of 0 if undefined
      }));
    };
  
    const transformedData = [
      {
        text: "Support",
        status: convertStatus(response.support)
      },
      {
        text: "Inquiry",
        status: convertStatus(response.inquiry)
      }
    ];
  
    return transformedData;
  };
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const HandleCloseDelete = () => {
    setShowDeleteAlert(false);
  };

  const bulkDelete = (selectedRow) => {
    setShowDeleteAlert(true);
    setData((prevState) => {
      return {
        ...prevState,
        selectedRows: selectedRow,
      };
    });
  };
  const HandelDelete = () => {
    const selectedArray = data.selectedRows.map((d) => d.id);

    deleteTickets(
      (res) => {
        let arrResults = [...data.results];
        selectedArray.map((d) => {
          const index = arrResults.findIndex((x) => {
            console.log(x.id);
            return x.id === d;
          });
          arrResults.splice(index, 1);
        });
        setData((prevState) => {
          let newData = {
            results: [...arrResults],
            total: prevState.total - 1,
          };
          if (arrResults.length == 0) {
            newData = {
              ...newData,
              params: {
                ...prevState.params,
                page: prevState.params.page - 1,
              },
            };
          }
          return {
            ...prevState,
            ...newData,
          };
        });
        HandleCloseDelete();
        addToast({
          type: "success",
          description: "Audits Deleted Successfully.",
          title: "Success",
        });
      },
      (error) => {
        addToast({
          type: ERROR,
          description: error.message,
          title: "Error",
        });
      },
      () => {},
      { ids: selectedArray }
    );
  };

  useEffect(() => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `ticketsLoading`,
          value: true,
        },
      ],
    });
    fetchTicketsSummery(
      (res) => {
        console.log(res);
        const transformed_res = transformApiResponse(res);
        console.log(transformed_res);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `inquiriesAndtickets`,
              value: transformed_res,
            },
            {
              type: UPDATE_PROP,
              prop: `allTicketsTotal`,
              value: res.all_tickets?.total,
            },
          ],
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: error.message,
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketsLoading`,
              value: false,
            },
          ],
        });
      }
    );
  }, []);
  let columns = useMemo(() => TicketsColumn(actions()));
  return {
    actions: {
    },
    setData,
    setShowDeleteAlert,
    HandleCloseDelete,
    HandelDelete,
    data,
    error,
    showDeleteAlert,
    actions,
    columns
  };
};

export default Logic;
