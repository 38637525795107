import React, { useState } from "react";
import Classes from "./Sidebar.module.css";
import SidebarNav from "./SidebarNav";
import navigation from "./NavLinks";
// import {
//   ProSidebar,
//   Menu,
//   MenuItem,
//   SidebarHeader,
//   SidebarFooter,
//   SidebarContent,
//   SubMenu,
// } from "react-pro-sidebar";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
// import "react-pro-sidebar/dist/css/styles.css";
import Logo from "../logo/Logo";

const Sidebar = ({ menuCollapse, setMenuCollapse, menuIconClick }) => {
  return (
    <div id="sidebar" className="sidebarContainer">
      {/* <ProSidebar
        collapsed={menuCollapse}
        className={Classes.sidebar}
        breakPoint="md"
      >
        <SidebarHeader>
          <Logo classes={Classes.logoSize} />
          <div className={Classes.logo}>GAWDA</div>
          <div className="closemenu" onClick={menuIconClick}>
            close
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <>
              <MenuItem icon={"tt"}>Dashboard </MenuItem>
              <SubMenu title="Components" icon={"tt"}>
                <MenuItem>Component 1</MenuItem>
                <MenuItem>Component 2</MenuItem>
              </SubMenu>
            </>
          </Menu>
        </SidebarContent>
      </ProSidebar> */}

      <ProSidebar
        collapsed={menuCollapse}
        className={Classes.sidebar}
        breakPoint="md"
      >
        <SidebarHeader>
          {menuCollapse ? (
            <div className={Classes.logo}>G</div>
          ) : (
            <Logo classes={Classes.logoSize} />
          )}
        </SidebarHeader>
        <SidebarContent>
          {/* <Menu iconShape="circle">
            <MenuItem
              icon={"jhjjj"}
              suffix={<span className="badge red">ggjjgj</span>}
            >
              gjjj
            </MenuItem>
            <MenuItem icon={"ikkk"}>okkk</MenuItem>
          </Menu>
          <Menu iconShape="circle">
            <SubMenu
              suffix={<span className="badge yellow">3</span>}
              title={"yy"}
              icon={"jnhjnj"}
            >
              <MenuItem> 1</MenuItem>
              <MenuItem> 2</MenuItem>
              <MenuItem> 3</MenuItem>
            </SubMenu>
            <SubMenu
              prefix={<span className="badge gray">3</span>}
              title="tt"
              icon={"dfgg"}
            >
              <MenuItem> 1</MenuItem>
              <MenuItem> 2</MenuItem>
              <MenuItem> 3</MenuItem>
            </SubMenu>
            <SubMenu
              title={"ikkk"}
              icon={"hhhh"}
            >
              <MenuItem>1 </MenuItem>
              <MenuItem> 2 </MenuItem>
              <SubMenu title={` 3`}>
                <MenuItem>3.1 </MenuItem>
                <MenuItem>3.2 </MenuItem>
                <SubMenu title={`3.3`}>
                  <MenuItem>3.3.1 </MenuItem>
                  <MenuItem>3.3.2 </MenuItem>
                  <MenuItem>3.3.3 </MenuItem>
                </SubMenu>
              </SubMenu>
            </SubMenu>
          </Menu>  */}

          <SidebarNav items={navigation} activeClass={Classes.activeLink} />
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
