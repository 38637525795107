import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DatePicker from "react-multi-date-picker";
import AppButton from "../../../components/general/app-button/AppButton";
import AppInput from "../../../components/general/app-input/AppInput";
import AppSelect from "../../../components/general/app-select/AppSelect";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import LoadingSpinner from "../../../components/general/loading-spinner/LoadingSpinner";
import Title from "../../../components/general/title/Title";
import ValidationProvider from "../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../helpers/hooks/use-logic";
import useValidator from "../../../helpers/hooks/use-validator";
import Styles from "./AddSubscription.module.css";
import { filterSelectStyles } from "./Constants";
import Logic, { INITIAL_STATE } from "./logic";

const datePickerStyle = {
  height: "100%",
  width: "100%",
  padding: "0 10px",
  boxShadow: "none",
  border: 0,
  display: "flex",
};

const datePickerContainerStyle = {
  width: "100%",
  padding: "2px 0",
};
const AddSubscription = () => {
  const { state, updateState, handleUpdateOrCreate, navigate, id } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: handleUpdateOrCreate,
    validations: {
      selectedCompany: { required: true, validation_name: "Company" },
      users: { required: true, validation_name: "Users" },
      // selectedPlan: { required: true, validation_name: "Plan" },
      selectedPricing: { required: true, validation_name: "Pricing" },
      status: { required: true, validation_name: "Status" },
      paymentStatus: { required: true, validation_name: "Payment Status" },
      startDate: {
        required: state.form.startDate === "",
        validation_name: "Start date",
      },
      endDate: {
        required:
          state.form.endDate === "" ||
          state.form.endDate < state.form.startDate,
        validation_name: "Expiry date",
      },
    },
  });

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Title title={`${id ? "Update" : "Add"} ${"subscription"}`} />
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
          {/* <form> */}
          <div className={Styles.formContainer}>
            <div className={Styles.sectionContainer}>
              <Title title={"Details"} border={true} />
              <div style={{ marginTop: "15px", padding: "0px 20px" }}>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    {/* {JSON.stringify(state.form)} */}
                    <ValidationProvider
                      error={errors.selectedCompany}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Company")}
                          className={Styles.label}
                        >
                          <AppSelect
                            options={state.companies.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedCompany}
                            onChange={(e) => onChange("selectedCompany", e)}
                            onBlur={(e) => onBlur("selectedCompany")}
                            defaultValue={state.form.selectedCompany}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.users}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Users")}
                          className={Styles.label}
                        >
                          <AppInput
                            type="number"
                            value={state.form.users}
                            onChange={(e) => onChange("users", e.target.value)}
                            onBlur={(e) => onBlur("users")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  {/* <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.selectedPlan}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Plan")}
                          className={Styles.label}
                        >
                          <AppSelect
                            options={state.plans.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedPlan}
                            onChange={(e) => onChange("selectedPlan", e)}
                            onBlur={(e) => onBlur("selectedPlan")}
                          />
                        </Label>
                      )}
                    />
                  </div> */}
                  {/* {JSON.stringify(state.form)} */}
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.selectedPricing}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Pricing")}
                          className={Styles.label}
                        >
                          <AppSelect
                            options={state.planPricing.map((element) => ({
                              ...element,
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedPricing}
                            onChange={(e) => {
                              onChange("selectedPricing", e);
                            }}
                            onBlur={(e) => onBlur("selectedPricing")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.status}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Status")}
                          className={Styles.label}
                        >
                          <AppSelect
                            options={state.status}
                            styles={filterSelectStyles}
                            value={state.form.status}
                            onChange={(e) => onChange("status", e)}
                            onBlur={(e) => onBlur("status")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.paymentStatus}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Payment status")}
                          className={Styles.label}
                        >
                          <AppSelect
                            options={state.paymentStatus}
                            styles={filterSelectStyles}
                            value={state.form.paymentStatus}
                            onChange={(e) => onChange("paymentStatus", e)}
                            onBlur={(e) => onBlur("paymentStatus")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.startDate}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Start date")}
                          className={Styles.label}
                        >
                          <div
                            className={Styles.inputContainer}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <DatePicker
                              id="start_date"
                              style={datePickerStyle}
                              containerStyle={datePickerContainerStyle}
                              placeholder="dd/mm/yyyy"
                              format="DD/MM/YYYY"
                              value={new Date(state.form.startDate)}
                              onChange={(date) => {
                                console.log(date);
                                onChange(
                                  "startDate",
                                  new Date(date).toISOString().split("T")[0]
                                );
                              }}
                              onBlur={(e) => onBlur("startDate")}
                            />

                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              className={Styles.datepickerIcon}
                            />
                          </div>
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.endDate}
                      render={({ requiredTitle }) => (
                        <Label
                          name={requiredTitle("Expiry date")}
                          className={Styles.label}
                        >
                          <div
                            className={Styles.inputContainer}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <DatePicker
                              id="end_date"
                              minDate={new Date(state.form.startDate)}
                              style={datePickerStyle}
                              containerStyle={datePickerContainerStyle}
                              placeholder="dd/mm/yyyy"
                              format="DD/MM/YYYY"
                              selected={state.form.endDate}
                              value={new Date(state.form.endDate)}
                              onChange={(date) => {
                                console.log(date);
                                onChange(
                                  "endDate",
                                  new Date(date).toISOString().split("T")[0]
                                );
                              }}
                              onBlur={(e) => onBlur("endDate")}
                            />

                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              className={Styles.datepickerIcon}
                            />
                          </div>
                        </Label>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              type="button"
              value={"Save"}
              onClick={handleSubmit}
              isLoading={state.saveLoading}
            />
            <AppButton
              className="whiteButton"
              type="button"
              onClick={() => navigate("/subscriptions")}
              value={"Cancel"}
              style={{ margin: "0px 15px" }}
            />
          </div>
        </LoadingSpinner>
      </Card>
    </div>
  );
};
export default AddSubscription;
