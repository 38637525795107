import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../components/general/app-button/AppButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
export const SystemLogsColumn = [
  {
    Header: "Timestamp",
    accessor: "timestamp",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Message",
    accessor: "message",
  },
];
