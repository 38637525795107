import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Tooltip, Title } from "chart.js";
import React from "react";
ChartJS.register(ArcElement, Legend, Tooltip, Title);

const areEqual = (prevProps, nextProps) => {
  if (
    JSON.stringify(prevProps.chartData) == JSON.stringify(nextProps.chartData)
  ) {
    return true;
  }
  return false;
};
const DoughnutChart = ({
  chartData,
  legend = true,
  legendPosition = "right",
  title = false,
  titleText = "",
  titleStyle = { color: "black", fontSize: 15, weight: undefined },
  titlePosition = "bottom",
  label,
  labelFontSize = 17,
}) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,

    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: legend,
        position: legendPosition,
      },
      title: {
        display: title,
        text: titleText,
        color: titleStyle.color,
        font: { size: titleStyle.fontSize, weight: titleStyle.weight },
        position: titlePosition,
      },
    },
    cutout: "80%",
    circumference: 270,
    rotation: 225,
  };
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Doughnut data={chartData} options={options} />
      {label && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "50%",
            left: 0,
            textAlign: "center",
            marginTop: "-20px",
            lineHeight: "20px",
            fontSize: labelFontSize,
          }}
        >
          <span>hi</span>
        </div>
      )}
    </div>
  );
};
export default React.memo(DoughnutChart, areEqual);
