import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllCompanies,
  getCompanyFilters,
} from "../../../api/services/SubcriptionServices";
import UseAppTable from "../../../helpers/hooks/use-app-table";
import { CustomerColumn } from "./CustomerColumn";

export const INITIAL_STATE = {
  addCustomerModal: false,
  activateDeactivateModal: false,
  targetIndex: null,
};
const Logic = (dispatch, state) => {
  let navigate = useNavigate();

  const actions = () => {
    return {
      navigate: navigate,
      dispatch: dispatch,
    };
  };
  const { data, setData, error, filterData } = UseAppTable({
    fetchTableData: getAllCompanies,
    fetchTableFilters: getCompanyFilters,
    payload: { pageSize: 10 },
  });

  console.log(filterData);

  let columns = useMemo(() => CustomerColumn(actions()));
  return { actions: {}, data, setData, error, columns, filterData };
};

export default Logic;
