import { Navigate, useRoutes } from "react-router-dom";
import DefaultLayout from "./layouts/default-layout/DefaultLayout";
import Login from "./pages/login/Login";
import NotFound from "./components/general/not-found/NotFound";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Customers from "./pages/customers/customers/Customers";
import CustomerDetails from "./pages/customers/customer-details/CustomerDetails";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import Tickets from "./pages/tickets/tickets-list/Tickets";
import SystemLogs from "./pages/systemlogs/SystemLogs";
import Dashboard from "./pages/dashboard/Dashboard";
import TicketDetails from "./pages/tickets/ticket-details/TicketDetails";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Logout from "./pages/logout/Logout";
import AddSubscription from "./pages/subscriptions/add-subscription/AddSubscription";
const Routers = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <DefaultLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/ticket-details/:ticketId",
          element: <TicketDetails />,
        },
        { path: "customers", element: <Customers /> },
        { path: "customer-details", element: <CustomerDetails /> },
        { path: "subscriptions", element: <Subscriptions /> },
        {
          path: "add-subscription",
          element: <AddSubscription />,
          children: [{ path: ":id", element: <AddSubscription /> }],

        },
        { path: "tickets", element: <Tickets /> },
        { path: "systemlogs", element: <SystemLogs /> },
      ],
    },

    { path: "login", element: <Login /> },
    { path: "reset-password", element: <ResetPassword /> },
    { path: "logout", element: <Logout /> },
    { path: "/404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};

export default Routers;
