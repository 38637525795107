import {
  faBan,
  faCircleCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";
import { UPDATE_PROP } from "../../../store/ActionTypes";
export const CustomerColumn = (actions) => {
  return [
    {
      Header: "Company",
      accessor: "name",
    },
    {
      Header: "# Users",
      accessor: "number_of_users",
      // Cell: ({ row }) => (
      //   <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      //     {row.original.users_count}
      //   </div>
      // ),
    },
    {
      Header: "Plan",
      accessor: "plan_name",
      // Cell: ({ row }) => {
      //   return (
      //     <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      //       {row.original?.subscriptions?.length > 0
      //         ? row.original.subscriptions
      //             ?.map((sub, index) => sub?.name)
      //             .join(" - ")
      //         : "-"}
      //     </div>
      //   );
      // },
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 50,
      minWidth: 50,
    },
    {
      Header: "Contact Person",
      accessor: "contact_person_name",
    },
    {
      Header: "Email",
      accessor: "contact_person_email",
    },
    {
      Header: "Mobile",
      accessor: "contact_person_mobile",
    },
    {
      Header: "Actions",
      // className: "frozen",
      style: {
        textAlign: "center",
      },
      Cell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {row.original.status == "Activated" ? (
            <AppButton
              classes="buttonIcon"
              style={{ color: "#fb3737" }}
              onClick={() => {
                // alert(JSON.stringify(row.original))
                actions.dispatch({
                  payload: [
                    {
                      type: UPDATE_PROP,
                      prop: "activateDeactivateModal",
                      value: true,
                    },
                    {
                      type: UPDATE_PROP,
                      prop: "targetIndex",
                      value: row.index,
                    },
                  ],
                });
              }}
              value="Deactivate"
              icon={
                <FontAwesomeIcon icon={faBan} style={{ marginRight: "5px" }} />
              }
            />
          ) : (
            <AppButton
              classes="buttonIcon"
              style={{ color: "#17c62e" }}
              onClick={() => {
                actions.dispatch({
                  payload: [
                    {
                      type: UPDATE_PROP,
                      prop: "activateDeactivateModal",
                      value: true,
                    },
                    {
                      type: UPDATE_PROP,
                      prop: "targetIndex",
                      value: row.index,
                    },
                  ],
                });
              }}
              value="Activate"
              icon={
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ marginRight: "5px" }}
                />
              }
            />
          )}
          <AppButton
            classes="buttonIcon"
            onClick={() => {
              // debugger;
              actions.navigate("/customer-details", {
                state: { customerId: row.original.id },
              });
            }}
            value="Edit"
            style={{
              color: "#17c62e",
            }}
            icon={
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
            }
          />
        </div>
      ),
    },
  ];
};
