import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
export const TicketsColumn = (actions) => {
  const navigate = useNavigate();
  return [
    {
      Header: "Ticket Number",
      accessor: "ticket_number",
    },
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Last Activity",
      accessor: "updated_at",
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: "Contact Person",
      accessor: "contact_person_name",
    },
    {
      Header: "Email",
      accessor: "contact_person_email",
    },
    {
      Header: "Mobile",
      accessor: "contact_person_mobile",
    },
    {
      Header: "Actions",
      // className: "frozen",
      style: {
        textAlign: "center",
      },
      Cell: ({ row: {original, values } }) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <AppButton
            classes="buttonIcon"
            style={{ color: "#fb3737" }}
            onClick={() => actions.bulkDelete([original])}
            value="Delete"
            icon={
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{ marginRight: "5px" }}
              />
            }
          />
          <AppButton
            classes="buttonIcon"
            onClick={() => navigate(`/ticket-details/${values.id}`)}
            value="view"
            style={{
              color: "#17c62e",
            }}
            icon={
              <FontAwesomeIcon icon={faEye} style={{ marginRight: "5px" }} />
            }
          />
        </div>
      ),
    },
  ];
};
