import styles from "./AddCustomerModal.module.css";
import Logic, { INITIAL_STATE } from "./logic";
import useValidator from "../../../../helpers/hooks/use-validator";
import Title from "../../../../components/general/title/Title";
import AppInput from "../../../../components/general/app-input/AppInput";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../helpers/hooks/use-logic";
import Label from "../../../../components/general/label/Label";
import AppModal from "../../../../components/general/app-modal/AppModal";
import AppButton from "../../../../components/general/app-button/AppButton";

const AddCustomerModal = ({ show, hide }) => {
    const { state, updateState, addCustomer } = useLogic({
        INITIAL_STATE,
        Logic,
        prop: { hide },
    });
    const { loading } = state;
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.customer,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "customer",
                value: nextFormState,
            });
        },
        onSubmit: () => {
            addCustomer();
        },
        validations: {
            companyName: {
                required: true,
                validation_name: "Company Name",
                maxLength: 80,
            },
            name: {
                required: true,
                validation_name: "Name",
            },
            email: { required: true, validation_name: "Email", isEmail: true },
            address: {
                required: true,
                validation_name: "Address",
            },
            commercial_reg: {
                required: true,
                validation_name: "Commercial Reg",
            },
            tax_reg: {
                required: true,
                validation_name: "Tax Reg",
            },
            description: {
                required: true,
                validation_name: "Description",
            },
            mobile: {
                required: true,
                validation_name: "Mobile",
                pattern: "^\\+\\d{1,3}?\\d{11}$",
            },
            whatsapp: {
                required: true,
                validation_name: "Whatsapp",
                pattern: "^\\+\\d{1,3}?\\d{11}$",
            },
            num_of_employee: {
                required: true,
                validation_name: "Number of Employees",
                isNumeric: true,
            },
            sector: {
                required: true,
                validation_name: "Sector",
            },
            industry: {
                required: true,
                validation_name: "Industry",
            },
            jobTitle: {
                required: true,
                validation_name: "Job Title",
            },
            website: {
                pattern:
                    "^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$",
                validation_name: "Website",
            },
        },
    });
    return (
        <AppModal
            show={show}
            onHide={() => {
                hide();
            }}
            className="modelContent"
            bodyStyle={{ paddingTop: 0 }}
            footerStyle={{ border: 0, paddingTop: 0 }}
            headerStyle={{ border: 0, paddingBottom: 0 }}
        >
            <div className={styles.sectionContainer}>
                <Title title="New Customer" border={true} />
                <div className={styles.groupInputs}>
                    <div className={styles.groupControl}>
                        <ValidationProvider
                            error={errors.companyName}
                            render={() => (
                                <Label name="Company Name" className={styles.label}>
                                    <AppInput
                                        type="text"
                                        className={styles.input}
                                        onChange={(e) => onChange("companyName", e.target.value)}
                                        onBlur={(e) => onBlur("companyName")}
                                        value={state.customer.companyName}
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    <div className={styles.groupControl}>
                        <ValidationProvider
                            error={errors.address}
                            render={() => (
                                <Label name="Address" className={styles.label}>
                                    <AppInput
                                        type="text"
                                        className={styles.input}
                                        onChange={(e) => onChange("address", e.target.value)}
                                        onBlur={(e) => onBlur("address")}
                                        value={state.customer.address}
                                        textarea
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    <div className={styles.groupControl}>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.commercial_reg}
                                render={() => (
                                    <Label name="Commercial Reg" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) =>
                                                onChange("commercial_reg", e.target.value)
                                            }
                                            onBlur={(e) => onBlur("commercial_reg")}
                                            value={state.customer.commercial_reg}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.tax_reg}
                                render={() => (
                                    <Label name="Tax Reg" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("tax_reg", e.target.value)}
                                            onBlur={(e) => onBlur("tax_reg")}
                                            value={state.customer.tax_reg}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.groupControl}>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.sector}
                                render={() => (
                                    <Label name="Sector" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("sector", e.target.value)}
                                            onBlur={(e) => onBlur("sector")}
                                            value={state.customer.sector}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.industry}
                                render={() => (
                                    <Label name="Industry" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("industry", e.target.value)}
                                            onBlur={(e) => onBlur("industry")}
                                            value={state.customer.industry}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.groupControl}>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.num_of_employee}
                                render={() => (
                                    <Label name="Number of Employees" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) =>
                                                onChange("num_of_employee", e.target.value)
                                            }
                                            onBlur={(e) => onBlur("num_of_employee")}
                                            value={state.customer.num_of_employee}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.website}
                                render={() => (
                                    <Label name="Website" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("website", e.target.value)}
                                            onBlur={(e) => onBlur("website")}
                                            value={state.customer.website}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.groupControl}>
                        <ValidationProvider
                            error={errors.description}
                            render={() => (
                                <Label name="Activity Description" className={styles.label}>
                                    <AppInput
                                        type="text"
                                        className={styles.input}
                                        onChange={(e) => onChange("description", e.target.value)}
                                        onBlur={(e) => onBlur("description")}
                                        value={state.customer.description}
                                        textarea
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    {/* <div className={styles.dividTitle}> */}
                    <p className={styles.sectionTitle}>Contact Person</p>
                    {/* </div>   */}
                    <div className={styles.groupControl}>
                        <ValidationProvider
                            error={errors.name}
                            render={() => (
                                <Label name="Name" className={styles.label}>
                                    <AppInput
                                        type="text"
                                        className={styles.input}
                                        onChange={(e) => onChange("name", e.target.value)}
                                        onBlur={(e) => onBlur("name")}
                                        value={state.customer.name}
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    <div className={styles.groupControl}>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.jobTitle}
                                render={() => (
                                    <Label name="Job Title" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("jobTitle", e.target.value)}
                                            onBlur={(e) => onBlur("jobTitle")}
                                            value={state.customer.jobTitle}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.email}
                                render={() => (
                                    <Label name="Email" className={styles.label}>
                                        <AppInput
                                            type="email"
                                            className={styles.input}
                                            onChange={(e) => onChange("email", e.target.value)}
                                            onBlur={(e) => onBlur("email")}
                                            value={state.customer.email}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.groupControl}>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.mobile}
                                render={() => (
                                    <Label name="Mobile" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("mobile", e.target.value)}
                                            onBlur={(e) => onBlur("mobile")}
                                            value={state.customer.mobile}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={styles.inputControl}>
                            <ValidationProvider
                                error={errors.whatsapp}
                                render={() => (
                                    <Label name="Whatsapp" className={styles.label}>
                                        <AppInput
                                            type="text"
                                            className={styles.input}
                                            onChange={(e) => onChange("whatsapp", e.target.value)}
                                            onBlur={(e) => onBlur("whatsapp")}
                                            value={state.customer.whatsapp}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.actionButton}>
                    <AppButton
                        className="primaryButton"
                        onClick={handleSubmit}
                        //   () => {
                        //   addAssignee(state.customer);
                        //   set();
                        //   setAssigneeValue();
                        // }}
                        isLoading={loading}
                        value="Save"
                        style={{ marginRight: "10px" }}
                        disabled={loading}
                    />
                    <AppButton
                        className="whiteButton"
                        onClick={() => {
                            hide();
                        }}
                        value="Cancel"
                        style={{ marginRight: "10px" }}
                        disabled={loading}
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default AddCustomerModal;
