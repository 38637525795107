import Storage from "../helpers/Storage";
import { AUTHENTICATION_TOKEN } from "../helpers/constants/StaticKeys";
import { UPDATE_PROP } from "../store/ActionTypes";

export const updateState = (actions) => {
  return (dispatch) => {
    actions.map((action) => {
      dispatch(action);
    });
  };
};

export const loginAction = (res) => {
  return (dispatch) => {
    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "user",
          value: res,
        },
      ])
    );
  };
};
export const logout = () => {
  return (dispatch) => {
    // Storage.removeItem(AUTHENTICATION_TOKEN);

    Storage.clear();

    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "user",
          value: null,
        },
      ])
    );
  };
};
export const updateToasts = (toasts) => {
  return (dispatch) => {
    dispatch(
      updateState([
        {
          type: UPDATE_PROP,
          prop: "toasts",
          value: toasts,
        },
      ])
    );
  };
};
