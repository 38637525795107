import React, { useEffect, useState } from "react";
import { INITIAL_STATE } from "../RtCommon";
import { useEffectSkipFirst } from "../Common";
import { ERROR } from "../../components/general/app-toast/AppToast";
import UseAppToast from "../../helpers/hooks/use-app-toast";

const UseAppTable = ({
    fetchTableData,
    fetchTableFilters = undefined,
    payload,
    shouldCall = true,
}) => {
    const [data, setData] = useState(INITIAL_STATE);
    const [filterData, setFilterData] = useState({
        filterKeys: {},
        filterData: [],
        loading: false,
    });
    const [error, setError] = useState(null);
    const { addToast } = UseAppToast();
    const fetchData = async (params, onSuccess) => {
        setData((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });

        const updatedPageParam = { page: params?.page ?? 1, pageSize: params?.pageSize ?? 10 };

        return fetchTableData(
            (res) => {
                setData((prevState) => {
                    return {
                        ...prevState,
                        results: res.data.response.data,
                        total: res.data.response.total,
                        params: {
                            ...prevState.params,
                            page: res.data.response.current_page - 1,
                        },
                        perPage: res.data.response.per_page,
                    };
                });

                onSuccess && onSuccess(res.data.response);
            },
            (error) => {
                addToast &&
                    addToast({
                        type: ERROR,
                        description: error.response.data.message || error.message,
                        title: "Error",
                    });
                setError(error.message || "Something went wrong!");
            },
            () => {
                setData((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                    };
                });
            },
            {
                ...data.params,
                ...params,
                ...updatedPageParam,
            }
        );
    };
    const fetchDataFilters = async (onSuccess) => {
        setFilterData((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        return fetchTableFilters(
            (res) => {
                const newFilterData = res.data.response.reduce(function (map, obj) {
                    map[obj.key] = obj;
                    return map;
                }, {});
                setFilterData((prevState) => {
                    return {
                        ...prevState,
                        filterKeys: newFilterData,
                        filterData: res.data.response,
                    };
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: error.message,
                    title: "Error",
                });
            },
            (f) => {}
        );
    };
    useEffectSkipFirst(() => {
        if (shouldCall) {
            fetchData({
                ...payload,
                page: data.params.page + 1,
            });
        }
    }, [data.params.page]);
    useEffectSkipFirst(() => {
        if (shouldCall) {
            fetchData({
                ...payload,
                filters: data.params.filters,
            });
        }
    }, [data.params.filters]);
    useEffect(() => {
        if (shouldCall) {
            fetchData(payload);
            fetchTableFilters && fetchDataFilters();
        }
    }, []);

    return {
        data,
        setData,
        error,
        filterData,
    };
};

export default UseAppTable;
