import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
// import background from "../../assets/images/background.png";
import Classes from "./Header.module.css";
import { useSelector } from "react-redux";
export const LeftNavLinks = [];
export const CenterNavLinks = () => {
  return [
    {
      name: "Overview",
      to: "/analytics/overview",
    },
    {
      name: "Performance",
      to: "/analytics/performance",
    },
    {
      name: "Action Plan",
      to: "/analytics/action-plan",
    },
    {
      name: "Export Data",
      to: "/analytics/audits",
    },
    {
      name: "Audit Trends",
      to: "/analytics/audit-trends",
    },
    {
      name: "Audit Summery",
      to: "/analytics/audit-summery",
    },
    {
      name: "Custom Search",
      to: "/analytics/custom-search",
    },
  ];
};
export const RightNavLinks = () => {
  const user = useSelector((state) => state.user);

  return [
    {
      to: "/base",
      align: "end",
      items: [
        {
          name: "Logout",
          to: "/logout",
        },
      ],
      component: (
        <div className={Classes.userIcon}>
          <h5>Welcome, {user?.name}</h5>
          <FontAwesomeIcon icon={faUserCircle} className={Classes.icon} />
        </div>
      ),
    },
  ];
};
