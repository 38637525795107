
export const SubColumn = (
  actions,
  translate = (term) => {
    return term;
  }
) => {
  return [
    {
      Header: "plan",
      accessor: "plan_name",

    },
    {
      Header: "status",
      accessor: "status",
    },
    {
      Header: "users",
      accessor: "number_of_users",
    },
    {
      Header: "Expiry date",
      accessor: "subscription_end_date",
    },
  ];
};
