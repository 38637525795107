import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./DisplayFilters.module.css";
import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const DisplayFilters = ({ filters, handleDelete }) => {
  return (
    <>
      {filters.map((item, index) => (
        <div className={Styles.filterContainer} key={index}>
          {item.label} {item.signLabel}
          {item.dropdownLabel
            ? ` "${item.dropdownLabel}"`
            : item.value
            ? ` "${item.value}"`
            : ""}
          {item.value2 && ` "${item.value2}"`}
          <FontAwesomeIcon
            icon={faTimes}
            className={Styles.filterIcon}
            style={{ color: "white", fontSize: "10px" }}
            onClick={() => {
              handleDelete(index);
            }}
          />
        </div>
      ))}
    </>
  );
};

export default DisplayFilters;
