import { faEdit, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../components/general/app-button/AppButton";
import RadioInput from "../../components/general/radio-input/RadioInput";
import Styles from "./Subscriptions.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
export const SubscriptionsColumn = (actions) => [
  {
    Header: "Company",
    accessor: "company_name",
  },
  {
    Header: "id",
    accessor: "id",
    show: false,
  },
  {
    Header: "Users",
    accessor: "number_of_users",
    maxWidth: 50,
    minWidth: 50,
  },
  {
    Header: "Plan",
    accessor: "plan_name",
  },
  {
    Header: "Status",
    accessor: "status",
    maxWidth: 70,
    minWidth: 70,
  },
  {
    Header: "Payment Status",
    accessor: "payment_status",
  },
  {
    Header: "Expiry Date",
    accessor: "subscription_end_date",
  },
  {
    Header: "Actions",
    // className: "frozen",
    //  minWidth: 400,
    // Cell: ({ row }) => (
    //   <div className={Styles.actionsContainer}>
    //     {/* RadioButtonGroup for status */}
    //     <div className={Styles.radioButtonGroup}>
    //       <RadioInput
    //         id={`active-status-${row.original.id}`}
    //         name={`status-${row.original.id}`}
    //         value="active"
    //         label={"Active"}
    //         isChecked={row.original.status === "active"}
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({ ...row.original, status: inputValue });
    //         }}
    //       />
    //       <RadioInput
    //         id={`pending-status-${row.original.id}`}
    //         name={`status-${row.original.id}`}
    //         value="pending"
    //         label={"Pending"}
    //         isChecked={row.original.status === "pending"}
    //         // handleChange={(inputValue) => {
    //         // actions.updateStatus(inputValue, row.original)
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({ ...row.original, status: inputValue });
    //         }}
    //         // }}
    //       />
    //       <RadioInput
    //         id={`expired-status-${row.original.id}`}
    //         name={`status-${row.original.id}`}
    //         value="expired"
    //         label={"Expired"}
    //         isChecked={row.original.status === "expired"}
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({ ...row.original, status: inputValue });
    //         }}
    //       />
    //       <RadioInput
    //         id={`closed-status-${row.original.id}`}
    //         name={`status-${row.original.id}`}
    //         value="closed"
    //         label={"Closed"}
    //         isChecked={row.original.status === "closed"}
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({ ...row.original, status: inputValue });
    //         }}
    //       />
    //     </div>

    //     {/* RadioButtonGroup for payment status */}
    //     <div className={Styles.radioButtonGroup}>
    //       <RadioInput
    //         id={`paid-payment-${row.original.id}`}
    //         name={`paid-payment-status-${row.original.id}`}
    //         value="paid"
    //         label={"Paid"}
    //         isChecked={row.original.payment_status === "paid"}
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({
    //             ...row.original,
    //             payment_status: inputValue,
    //           });
    //         }}
    //       />
    //       <RadioInput
    //         id={`pending-payment-${row.original.id}`}
    //         name={`pending-payment-status-${row.original.id}`}
    //         value="pending"
    //         label={"Pending"}
    //         isChecked={row.original.payment_status === "pending"}
    //         handleChange={(inputValue) => {
    //           actions.updateStatus({
    //             ...row.original,
    //             payment_status: inputValue,
    //           });
    //         }}
    //       />
    //     </div>
    //   </div>
    // ),
    Cell: ({ row}) => (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* {console.log("sub row", original)} */}
        <AppButton
          classes="buttonIcon"
          onClick={() => actions.navigate(`/add-subscription/${row.original.id}`)}
          value={"Edit"}
          style={{
            color: "#2e5bff",
          }}
          icon={<FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px" }} />}
        />
        <div className={Styles.radioButtonGroup}>
          <RadioInput
            id={`active-status-${row.original.id}`}
            name={`status-${row.original.id}`}
            value="active"
            label={"Active"}
            isChecked={row.original.status === "active"}
            handleChange={(inputValue) => {
              actions.updateStatus({ ...row.original, status: inputValue });
            }}
          />
          <RadioInput
            id={`pending-status-${row.original.id}`}
            name={`status-${row.original.id}`}
            value="pending"
            label={"Pending"}
            isChecked={row.original.status === "pending"}
            // handleChange={(inputValue) => {
            // actions.updateStatus(inputValue, row.original)
            handleChange={(inputValue) => {
              actions.updateStatus({ ...row.original, status: inputValue });
            }}
            // }}
          />
          <RadioInput
            id={`expired-status-${row.original.id}`}
            name={`status-${row.original.id}`}
            value="expired"
            label={"Expired"}
            isChecked={row.original.status === "expired"}
            handleChange={(inputValue) => {
              actions.updateStatus({ ...row.original, status: inputValue });
            }}
          />
          <RadioInput
            id={`closed-status-${row.original.id}`}
            name={`status-${row.original.id}`}
            value="closed"
            label={"Closed"}
            isChecked={row.original.status === "closed"}
            handleChange={(inputValue) => {
              actions.updateStatus({ ...row.original, status: inputValue });
            }}
          />
        </div>

        {/* RadioButtonGroup for payment status */}
        <div className={Styles.radioButtonGroup}>
          <RadioInput
            id={`paid-payment-${row.original.id}`}
            name={`paid-payment-status-${row.original.id}`}
            value="paid"
            label={"Paid"}
            isChecked={row.original.payment_status === "paid"}
            handleChange={(inputValue) => {
              actions.updateStatus({
                ...row.original,
                payment_status: inputValue,
              });
            }}
          />
          <RadioInput
            id={`pending-payment-${row.original.id}`}
            name={`pending-payment-status-${row.original.id}`}
            value="pending"
            label={"Pending"}
            isChecked={row.original.payment_status === "pending"}
            handleChange={(inputValue) => {
              actions.updateStatus({
                ...row.original,
                payment_status: inputValue,
              });
            }}
          />
        </div>
      </div>
    ),
  },
];
