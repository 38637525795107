import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/services/UserServices";
import { encrypted } from "../../helpers/Common";
import { AUTHENTICATION_TOKEN } from "../../helpers/constants/StaticKeys";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { loginAction } from "../../store/Actions";
import { UPDATE_PROP } from "../../store/ActionTypes";
export const INITIAL_STATE = {
  form: {
    username: "",
    password: "",
  },
  loading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const reduxDispatch = useDispatch();
  const loginHandler = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `loading`,
          value: true,
        },
      ],
    });

    login(
      async (res) => {
        await Storage.setItem(
          AUTHENTICATION_TOKEN,
          res.data.response.access_token
        );
        addToast({
          type: "success",
          description: "Login successfully",
          title: "Success",
        });

        reduxDispatch(loginAction(res.data.response));
        navigate("/");
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: state.form.username,
        password: encrypted(state.form.password),
      }
    );
  };
  return { actions: { loginHandler } };
};

export default Logic;
