import { useState } from "react";
import { useEffectSkipFirst } from "../Common.js";

import {
  customValidate,
  emailValidator,
  exactValidator,
  imageValidator,
  maxLengthValidator,
  maxValidator,
  minLengthValidator,
  minValidator,
  numericValidator,
  patternValidator,
  requiredValidator,
} from "../validators.js";

const useValidator = ({
  defaultValues = {},
  setValue = () => {},
  validations = {},
  onSubmit,
  watch = [],
}) => {
  // debugger;
  //const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(
    Object.entries(defaultValues).reduce((acc, [field, fieldValue]) => {
      acc[field] = {
        dirty: false,
        error: false,
        message: "",
      };
      return acc;
    }, {})
  );
  const [watchValue, setWatchValue] = useState({
    watchField: null,
    exactField: null,
  });
  const touchErrors = (errors) => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
      acc[field] = {
        ...fieldError,
        dirty: true,
      };
      return acc;
    }, {});
  };
  const validateForm = ({ form, errors }) => {
    let isValid = true;

    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    nextErrors = touchErrors(errors);
    if (validations) {
      for (const key in validations) {
        const validateMessage = validateFeild({
          form,
          field: key,
          errors: nextErrors,
        });
        nextErrors[key].error = !!validateMessage["error"]["message"];
        nextErrors[key].message = validateMessage["error"]["message"];
        if (!validateMessage["isValid"]) isValid = false;
      }
    }

    return {
      isValid,
      errors: nextErrors,
    };
  };
  const validateFeild = ({ form, field, errors }) => {
    let isValid = true;
    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (validations[field]) {
      let fieldName = validations[field].validation_name
        ? validations[field].validation_name
        : field;
      if (nextErrors[field].dirty && field) {
        if (validations[field]["required"]) {
          const requiredMessage = requiredValidator(form[field], fieldName);

          nextErrors[field].error = !!requiredMessage;
          nextErrors[field].message = requiredMessage;
          if (!!requiredMessage) isValid = false;
        }

        if (isValid && validations[field]["isEmail"]) {
          const emailMessage = emailValidator(form[field], fieldName);

          nextErrors[field].error = !!emailMessage;
          nextErrors[field].message = emailMessage;
          if (!!emailMessage) isValid = false;
        }

        if (isValid && validations[field]["isImage"]) {
          const imageMessage = imageValidator(form[field], fieldName);

          nextErrors[field].error = !!imageMessage;
          nextErrors[field].message = imageMessage;
          if (!!imageMessage) isValid = false;
        }

        if (isValid && validations[field]["pattern"]) {
          const patternMessage = patternValidator(
            validations[field]["pattern"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!patternMessage;
          nextErrors[field].message = patternMessage;
          if (!!patternMessage) isValid = false;
        }

        if (isValid && validations[field]["maxLength"]) {
          const maxLengthMessage = maxLengthValidator(
            validations[field]["maxLength"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!maxLengthMessage;
          nextErrors[field].message = maxLengthMessage;
          if (!!maxLengthMessage) isValid = false;
        }

        if (isValid && validations[field]["minLength"]) {
          const minLengthMessage = minLengthValidator(
            validations[field]["minLength"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!minLengthMessage;
          nextErrors[field].message = minLengthMessage;
          if (!!minLengthMessage) isValid = false;
        }

        if (isValid && validations[field]["isNumeric"]) {
          const numericMessage = numericValidator(form[field], fieldName);
          nextErrors[field].error = !!numericMessage;
          nextErrors[field].message = numericMessage;
          if (!!numericMessage) isValid = false;
        }

        if (
          isValid &&
          validations[field]["isNumeric"] &&
          validations[field]["min"]
        ) {
          const minMessage = minValidator(
            validations[field]["min"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!minMessage;
          nextErrors[field].message = minMessage;
          if (!!minMessage) isValid = false;
        }

        if (
          isValid &&
          validations[field]["isNumeric"] &&
          validations[field]["max"]
        ) {
          const maxMessage = maxValidator(
            validations[field]["max"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!maxMessage;
          nextErrors[field].message = maxMessage;
          if (!!maxMessage) isValid = false;
        }

        if (isValid && validations[field]["validate"]) {
          const customValidateMessage = customValidate(
            validations[field]["validate"],
            form[field],
            fieldName
          );
          nextErrors[field].error = !!customValidateMessage;
          nextErrors[field].message = customValidateMessage;
          if (!!customValidateMessage) isValid = false;
        }

        if (isValid && validations[field]["exact"]) {
          let field2 = validations[validations[field]["exact"]].validation_name
            ? validations[validations[field]["exact"]].validation_name
            : validations[field]["exact"];
          const exactMessage = exactValidator(
            form[field],
            fieldName,
            form[validations[field]["exact"]],
            field2
          );

          nextErrors[field].error = !!exactMessage;
          nextErrors[field].message = exactMessage;
          if (!!exactMessage) isValid = false;
        }
      }
    }

    setErrors(nextErrors);

    return {
      isValid,
      error: nextErrors[field],
    };
  };

  useEffectSkipFirst(() => {
    if (errors[watchValue.exactField]?.dirty) {
      validateFeild({
        form: defaultValues,
        field: watchValue.exactField,
        errors,
      });
    }
  }, [defaultValues[watchValue.watchField]]);

  const onBlur = (name) => {
    const field = name;
    const fieldError = errors[field];
    // if (fieldError.dirty) return;
    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateFeild({ form: defaultValues, field, errors: updatedErrors });
  };

  const onChange = (name, value) => {
    const field = name;
    const nextFormState = {
      ...defaultValues,
      [field]: value,
    };
    // updateState({
    //   prop: "form",
    //   value: nextFormState,
    // });

    setValue(nextFormState);
    if (errors[field].dirty) {
      validateFeild({
        form: nextFormState,
        errors,
        field,
      });
    }
    const watchField = watch.filter((p) => p === field)[0];
    const exactField = Object.keys(validations).filter(
      (element) => validations[element]["exact"] === field
    )[0];
    if (watchField && exactField) {
      setWatchValue({
        watchField: watchField,
        exactField: exactField,
      });
    }
  };

  // const register = (fieldName, value) => {
  //   return {
  //     name: fieldName,
  //     onChange: (e) => {
  // onChange(fieldName, value);
  //     },
  //     onBlur: () => {
  //       onBlur(fieldName);
  //     },
  //   };
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({
      form: defaultValues,
      errors,
    });
    if (!isValid) return;
    // alert(JSON.stringify(defaultValues, null, 2));
    onSubmit && onSubmit();
  };

  return {
    validateForm,
    onBlur,
    onChange,
    errors,
    handleSubmit,
    // useWatch,
  };
};
export default useValidator;
