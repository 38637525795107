import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TableLayout from "../../../components/general/table-layout/TableLayout";
import useLogic from "../../../helpers/hooks/use-logic";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import ActivateNDeactivateModal from "../components/activate-deactivate-modal/ActivateDeactivateModal";
import AddCustomerModal from "../components/add-customer-modal/AddCustomerModal";
import Logic, { INITIAL_STATE } from "./logic";

const Customers = () => {
  // request  data
  const { state, updateState, data, setData, error, columns, filterData } =
    useLogic({
      INITIAL_STATE,
      Logic,
    });
  // const [filterData, setFilterData] = useState({
  //     filterKeys: {},
  //     filterData: [],
  // });
  console.log(data);
  return (
    <>
      <TableLayout
        tableColumns={columns}
        setData={setData}
        title={`Customers (${data.total})`}
        showButton={true}
        data={data}
        error={error}
        buttonValue={"Add"}
        buttonIcon={
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
        }
        buttonOnClick={() =>
          updateState({
            type: UPDATE_PROP,
            prop: "addCustomerModal",
            value: true,
          })
        }
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        filterData={filterData}
        showFilter={true}
      />
      {state.addCustomerModal && (
        <AddCustomerModal
          show={state.addCustomerModal}
          hide={() => {
            updateState({
              type: UPDATE_PROP,
              prop: "addCustomerModal",
              value: false,
            });
          }}
        />
      )}
      {state.activateDeactivateModal && (
        <ActivateNDeactivateModal
          setData={setData}
          show={state.activateDeactivateModal}
          hide={() => {
            updateState({
              type: UPDATE_PROP,
              prop: "activateDeactivateModal",
              value: false,
            });
          }}
          targetCompany={data.results[state.targetIndex]}
        />
      )}
    </>
  );
};
export default Customers;
