import { $axios, $securedAxios } from "../axios";

export const registerNewCustomer = (params, onSuccess, onError, onFinally) => {
  $securedAxios
    .post(`/registercompany`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getAllCompanies = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(
      `/companies?pageSize=${params?.pageSize ?? 9999}&page=${
        params?.page ?? 1
      }`,
      {
        filters: params.filters,
        count_relations: ["users"],
      }
    )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getCompany = (onSuccess, onError, onFinally, customerId) => {
  $securedAxios
    .post(`/companies?pageSize=${10}&page=${1}`, {
      filters: [{ key: "companies.id", sign: "is_equal", value: customerId }],
      count_relations: ["users"],
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getSubscriptionForSpecificCompany = (
  onSuccess,
  onError,
  onFinally,
  params
) => {
  $securedAxios
    .post(`subscriptions/for/specific/company?page=${params.page}`, {
      company_id: params.customerId,
      filters: params.filters,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getCompanyUser = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`/company/users?page=${params.page}`, {
      company_id: params.customerId,
      filters: params.filters,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getCompanyFilters = (onSuccess, onError, onFinally) => {
  $securedAxios
    .get(`/companies/filters`)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const updateCompany = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`/companies/update`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateSubscriptions = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`subscriptions/store`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getAllSubscriptions = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`subscriptions/get?page=${params.page}`, {
      filters: params.filters,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getSubscriptionsRevenue = (onSuccess, onError, onFinally) => {
  $securedAxios
    .post(`/subscriptions/revenue/for/eachplan`)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getSubscriptionSummary = (
  onSuccess,
  onError,
  onFinally,
  params
) => {
  $securedAxios
    .post("subscriptions/summary")
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getAllPlans = (onSuccess, onError, onFinally) => {
  $securedAxios
    .post(`/plans/get`)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getPlanPricing = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`/plan/pricing/get`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getSubscription = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`/subscriptions/specific`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const storeSubscription = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`plan/pricing/store`, params)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
