import { faEdit, faEye, faTrashAlt, faBan, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";
import { UPDATE_PROP } from "../../../store/ActionTypes";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
export const UsersColumn = (actions) => {
    return [
        {
            Header: "Name",
            accessor: "name",
        },

        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Welcome email status",
            accessor: "welcome_email",
        },
        {
            Header: "Created at",
            accessor: "created_at",
        },
        {
            Header: "Updated at",
            accessor: "updated_at",
        },
    ];
};
