import axios from "axios";
import { baseURL } from "../config";
import { AUTHENTICATION_TOKEN } from "../helpers/constants/StaticKeys";

export const $axios = axios.create({
    baseURL: baseURL,
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
});
export const $securedAxios = axios.create({
    baseURL: baseURL,
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
});

const requestHandler = (request) => {
    const authToken = Storage.getItem(AUTHENTICATION_TOKEN);
    // const authToken =
    //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNmNlZDE0ZmMyNzAzNGE3YmU2NjFmYTE1NmI5M2YyZDk2OTA5ZGY0MTk4NWUyMTc3MWEzZTYxZjM3MjYxYmM4NzYwZDVkNGZkZGZlYjQzN2MiLCJpYXQiOjE2NTUyOTE3MTcuNDc1MzcyLCJuYmYiOjE2NTUyOTE3MTcuNDc1Mzc0LCJleHAiOjE2ODY4Mjc3MTcuMzk0MDIyLCJzdWIiOiI1OSIsInNjb3BlcyI6W119.I7MlMBtAWuep1XbA6HZ6QUlKlp1IPqwImOTqyiBYdSqJnT1ZE7PxBiEpf5wfv6RiGvAikTm31hGB4Fngv9SJtPPX4NxRilpWOsbDbeaF0_qvXigQ_nhJ93ZgmVy9-PphLtPQGuwhNcXysvDt4UXXZkR11PZaRpg8ED5CyNNVA7UK5RFsWJPbs6eX_9I99G_dLGeffWC_LS-9fQhvr4GY27l_-HWvRPItEKBMBKaNQN1RgCU-2KV5eox4dST3qzFX5vPGiZ20Aj4JZtq_Ozqmxro2MDHsB9iZsyWdQvJqx7D6l7wGHDjybf8EFShhQNEoSXIjq5N1l76JyIMx-EDRvKnh5Nk86iQ3kURqEWaEZ_oVFXWjbML-3tVfDYwVdjx5AAE4uHl_U-SwBB0RSTYGD727-W3VOLdvzAv8lHf1M2dNeGxUVXsRMy0ea1GDrFz-Q7wUGpBkRQX9kNHLYY8BbLmgO1uTtBuq70JsC3luy_1PuoQWaW_X_n7Pm9VL1eJV3sghkdjrPKLsXErnsGbdSBdROfDlJ1d4r8UBX5JT968OUsG2DDG0hPFzRgVfbFHFJl3UjDKPQDAHK43Z3QYYmYEmqvGNPVKkjN_OoyIr4imkmY7ACxHOhaLDmQ25hhi3khg8LGeNOPM60iUKq7-Vqdlz65yEtc2GE5H4tJHaNfg";
    let headers = {};
    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }
    request.headers.common = {
        ...request.headers.common,
        ...headers,
    };

    return request;
};

const responseHandler = (response) => {
    if (response.status === 401) {
        window.location = "/login";
    }

    return response;
};

const errorHandler = (error) => {
    return Promise.reject(error);
};

// UNSECURED RESPONSE HANDLER
$axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// SECURED REQUEST HANDLER
$securedAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

// SECURED RESPONSE HANDLER
$securedAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);
export const secureGet = (url, onSuccess, onError, onComplete = (f) => {}) => {
    $securedAxios
        .get(url)

        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally((f) => {
            onComplete(f);
        });
};

export const securePost = (url, body, onSuccess, onError, onComplete = (f) => {}) => {
    $securedAxios
        .post(url, body)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally((f) => {
            onComplete(f);
        });
};
export const unsecureGet = (url, onSuccess, onError, onComplete = (f) => {}) => {
    console.log("unsecureGet", url);
    $axios
        .get(url)

        .then((response) => {
            console.log("unsecureGet success");
            onSuccess(response);
        })
        .catch((error) => {
            console.log("unsecureGet error");
            onError(error);
        })
        .finally((f) => {
            console.log("unsecureGet finally");
            onComplete(f);
        });
};

export const unsecurePost = (url, body, onSuccess, onError, onComplete = (f) => {}) => {
    $axios
        .post(url, body)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally((f) => {
            onComplete(f);
        });
};
