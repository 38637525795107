import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AppSidebar, AppHeader } from "../../components/index";
import HeaderRight from "../../components/header/header-right/HeaderRight";
import Classes from "./DefaultLayout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AUTHENTICATION_TOKEN } from "../../helpers/constants/StaticKeys";
import { fetchLoggedinUser } from "../../api/services/UserServices";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/Actions";
const DefaultLayout = () => {
    const [menuCollapse, setMenuCollapse] = useState(false);
    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };
    const dispatch = useDispatch();
    const token = Storage.getItem(AUTHENTICATION_TOKEN);

    useEffect(() => {
        if (token) {
            fetchLoggedinUser(
                (res) => {
                    // console.log(res);
                    dispatch(loginAction(res.data.response));
                },
                (e) => {},
                () => {}
            );
        }
    }, []);
    return (
        <div style={{ display: "flex", color: "black" }}>
            <AppSidebar
                menuCollapse={menuCollapse}
                setMenuCollapse={setMenuCollapse}
                menuIconClick={menuIconClick}
            />
            <div style={{ flex: 4, borderWidth: 1 }}>
                <AppHeader
                    right={<HeaderRight />}
                    classes={Classes.bgHeader}
                    left={
                        <FontAwesomeIcon
                            onClick={menuIconClick}
                            icon={faBars}
                            style={{
                                margin: "0px 15px",
                                color: "var(--primary)",
                                fontSize: 25,
                            }}
                        />
                        // <div className="closemenu" onClick={menuIconClick}>
                        //   close
                        // </div>
                    }
                />
                <div style={{ padding: 20 }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default DefaultLayout;
