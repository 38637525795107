export const Columns = [
  {
    Header: "Customer",
    accessor: "company_name",
  },
  {
    Header: "Subscription",
    accessor: "plan_name",
  },
  {
    Header: "Expiry Date",
    accessor: "expiration_end_date",
  },
];
