import React, { useRef, useEffect } from "react";
import { ENCRYPT_KEY } from "./constants/StaticKeys";
import CryptoJS from "react-native-crypto-js";

// import CryptoJS from "crypto-js";
// import { AUTHENTICATION_TOKEN, PRIVILEGES } from "./constants/StaticKeys";
export const useEffectSkipFirst = (callback, dependencies) => {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
        } else {
            callback();
        }
    }, [...dependencies]);
};
export const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    };
};
export const encrypted = (str) => {
    let JsonFormatter = {
        stringify: function (cipherParams) {
            // create json object with ciphertext
            var jsonObj = {
                ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
            };
            // optionally add iv or salt
            if (cipherParams.iv) {
                jsonObj.iv = cipherParams.iv.toString();
            }
            if (cipherParams.salt) {
                jsonObj.s = cipherParams.salt.toString();
            }
            // stringify json object
            return JSON.stringify(jsonObj);
        },
        parse: function (jsonStr) {
            // parse json string
            var jsonObj = JSON.parse(jsonStr);
            // extract ciphertext from json object, and create cipher params object
            var cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
            });
            // optionally extract iv or salt
            if (jsonObj.iv) {
                cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
            }
            if (jsonObj.s) {
                cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
            }
            return cipherParams;
        },
    };

    let encrypted_str = CryptoJS.AES.encrypt(JSON.stringify(str), ENCRYPT_KEY, {
        format: JsonFormatter,
    }).toString();

    return encrypted_str;
};
